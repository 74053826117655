import React from "react";
import MainLayout from "../../layouts/MainLayout";
import TransferList from "./TransferList";
import TransferView from "./TransferView";
import TransferAdd from "./TransferAdd";

function Transfer() {
  let [TransferViewScreen, setTransferViewScreen] = React.useState(0);
  const [selectId, setSelectId] = React.useState(null);

  return (
    <div>
      <MainLayout>
        {TransferViewScreen === 0 ? (
          <TransferList
            Vista={{ TransferViewScreen, setTransferViewScreen }}
            Transfer={{ selectId, setSelectId }}
          />
        ) : TransferViewScreen === 1 ? (
          <TransferView
            Vista={{ TransferViewScreen, setTransferViewScreen }}
            Transfer={{ selectId, setSelectId }}
          />
        ) : TransferViewScreen === 2 && (
          <TransferAdd
            Vista={{ TransferViewScreen, setTransferViewScreen }}
            Transfer={{ selectId, setSelectId }}
          />
        )}
      </MainLayout>
    </div>
  );
}

export default Transfer;

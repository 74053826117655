import React from "react";
//import Backgrounder from "../assets/Images/background2.jpg";
import logoAdmin from "../assets/Images/Ipunsoft.png";
import Helpers from "./Helpers";
import { Image } from "react-bootstrap";

function Warning({children}) {
  const [showAlert, setShowAlert] = React.useState(true);

  
  React.useEffect(() => {
    const verifyConnection = () => {
      Helpers.verifyAPI().then((data) => {
        setShowAlert(data);
      });
    };
  
    setInterval(() => {
      verifyConnection();
    }, 15000);
  
  }, []);

  return (
    <>
      {showAlert ? (
        <>{children}</>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000000,
            //backgroundImage: `url(${Backgrounder})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Image
            src={logoAdmin}
            style={{
              width: 340,
              height: 90,
              marginBottom: 20,
            }}
          />
          <h3 className="text-dark mb-4">Experimentando Inconvenientes</h3>
          <span className="text-dark fw-bold">Volveremos en Breve...</span>
        </div>
      )}
    </>
  );
}

export default Warning;

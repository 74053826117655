import React from "react";
import backgroundMovie from "../assets/Movies/background2.webm";

import Lottie from "lottie-react-web";
import animationLogin from "../assets/lotties/login.json";
import logoAdmin from "../assets/Images/Ipunsoft.png";
import Warning from "../libs/Warning";

function LoginLayout({ children }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationLogin,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="maxAll">
      <Warning>
        <video
          muted
          autoPlay
          playsInline
          loop
          className="videoBackground"
          src={backgroundMovie}
        >
          <source src={backgroundMovie} type="video/mp4" />
        </video>
        <div className="loginCard">
          <div className="text-center">
            <img src={logoAdmin} alt="logoadmin" className="logoAdmin" />
          </div>
          <Lottie options={defaultOptions} height={200} width={200} />
          {children}
        </div>
      </Warning>
    </div>
  );
}

export default LoginLayout;

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'

function TransferList(props) {
  return (
    <Card className='bg-transparent border-0 shadow-sm'>
        <Card.Header className='border-0'>
        <Row>
            <Col>
              <span className="fw-bold d-flex justify-content-start align-items-center h-100">
                Gestión de Traspasos
              </span>
            </Col>
            <Col className="text-end">
              <Button
                variant="success"
                onClick={(e) => {
                  e.preventDefault();
                  props.Vista.setTransferViewScreen(2);
                }}
              >
                <FontAwesomeIcon icon={faPlus} className="fa-fw me-2" />
                Nuevo Traspaso
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className='bg-transparent'>
            Lista de Transferencia
        </Card.Body>
        <Card.Footer className='border-0'></Card.Footer>
    </Card>
  )
}

export default TransferList

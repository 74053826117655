import React from "react";
import MainLayout from "../../layouts/MainLayout";

function Accounting() {
  return (
    <div>
      <MainLayout>
        Cuentas Contables
      </MainLayout>
    </div>
  );
}

export default Accounting;

import React from "react";
import MainLayout from "../../layouts/MainLayout";
import LicencesList from "./LicencesList";
import LicencesAdd  from "./LicencesAdd";

function Licences() {
  
  const [LicenceView, setLicenceView] = React.useState(0)
  const [selectId, setSelectId] = React.useState(null);

  return (
    <div>
      <MainLayout>
        {
        LicenceView === 0 ? <LicencesList Vista={{ LicenceView, setLicenceView }} Licencia={{ selectId, setSelectId }} /> : LicenceView === 1 ? <LicencesAdd Vista={{ LicenceView, setLicenceView }} /> : ''
        }
        
      </MainLayout>
    </div>
  );
}

export default Licences;

import React from "react";
import MainLayout from "../../layouts/MainLayout";

function Credits() {
  return (
    <div>
      <MainLayout>
        Créditos
      </MainLayout>
    </div>
  );
}

export default Credits;

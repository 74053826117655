import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import {
  Card,
  Button,
  Row,
  Col,
  InputGroup,
  Form,
  Image,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faSave,
  faSignature,
} from "@fortawesome/free-solid-svg-icons";
import InputGroupText from "react-bootstrap/esm/InputGroupText";
import Helpers from "../../libs/Helpers";
import { useNavigate } from "react-router-dom";

function NewCert(props) {
  const navigation = new useNavigate();

  const token = localStorage.getItem("session");
  const [sucursales, setSucursales] = useState([]);
  const tipodoc = "Certificado";
  const [clase, setClase] = useState();
  const [nombres_completos, setNombresCompletos] = useState();
  const [padre, setPadre] = useState();
  const [madre, setMadre] = useState();
  const [congregacion, setCongregacion] = useState();
  const [ciudad, setCiudad] = useState();
  const [dia, setDia] = useState();
  const [mes, setMes] = useState();
  const [anio, setAnio] = useState();
  const [firma1, setFirma1] = useState();
  const [firma2, setFirma2] = useState();
  const [firma3, setFirma3] = useState();

  const fileRef1 = useRef();
  const fileRef2 = useRef();
  const fileRef3 = useRef();

  useEffect(() => {
    Helpers.postAuth("/branches/all", "POST", token, {}).then((data1) => {
      setSucursales(data1);
    });
  }, [token]);

  const saveCertificate = (e) => {
    e.preventDefault();
    Helpers.postAuth("/documents/add", "POST", token, {
      tipodoc,
      clase,
      nombres_completos: Helpers.Capitalize(nombres_completos),
      padre: Helpers.Capitalize(padre),
      madre: Helpers.Capitalize(madre),
      congregacion,
      ciudad: Helpers.Capitalize(ciudad),
      dia,
      mes,
      anio,
      firma1,
      firma2,
      firma3,
    }).then((data, err) => {
      if (err) throw err;
      if (data) {
        navigation(0);
      }
    });
  };

  return (
    <div>
      <Card className="bg-transparent border-0 shadow-sm">
        <Card.Header className="border-0">
          <Button
            onClick={(e) => {
              e.preventDefault();
              props.Vista.setDocView(0);
            }}
            variant="transparent"
            className="fw-bold"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="fa-fw me-2" />
            Agregar Nuevo Certificado
          </Button>
        </Card.Header>
        <Card.Body
          style={{
            padding: 5,
          }}
        >
          <Row>
            <Col sm={12} md={6} className="mb-1 mt-1">
              <InputGroup>
                <InputGroupText style={{ width: 200 }}>
                  Clase de Certificado
                </InputGroupText>
                <Form.Select
                  onChange={(e) => setClase(e.target.value)}
                  value={clase}
                >
                  <option value="">Selecciona...</option>
                  <option value="Presentación">Presentación</option>
                  <option value="Bautismo">Bautismo</option>
                  <option value="Matrimonio">Matrimonio</option>
                </Form.Select>
              </InputGroup>
            </Col>
            {clase !== "Matrimonio" && (
            <Col sm={12} md={6} className="mb-1 mt-1">
              <InputGroup>
                <InputGroupText style={{ width: 200 }}>
                  Nombres Completos
                </InputGroupText>
                <Form.Control
                  type="text"
                  onChange={(e) => setNombresCompletos(e.target.value)}
                  value={nombres_completos}
                />
              </InputGroup>
            </Col>
            )}
            {clase === "Presentación" && (
              <Col sm={12} md={6} className="mb-1 mt-1">
                <InputGroup>
                  <InputGroupText style={{ width: 200 }}>
                    Padre del Niño(a)
                  </InputGroupText>
                  <Form.Control
                    type="text"
                    onChange={(e) => setPadre(e.target.value)}
                    value={padre}
                  />
                </InputGroup>
              </Col>
            )}
            {clase === "Presentación" && (
              <Col sm={12} md={6} className="mb-1 mt-1">
                <InputGroup>
                  <InputGroupText style={{ width: 200 }}>
                    Madre del Niño(a)
                  </InputGroupText>
                  <Form.Control
                    type="text"
                    onChange={(e) => setMadre(e.target.value)}
                    value={madre}
                  />
                </InputGroup>
              </Col>
            )}
            {clase === "Matrimonio" && (
              <Col sm={12} md={6} className="mb-1 mt-1">
                <InputGroup>
                  <InputGroupText style={{ width: 200 }}>
                    Hermano
                  </InputGroupText>
                  <Form.Control
                    type="text"
                    onChange={(e) => setPadre(e.target.value)}
                    value={padre}
                  />
                </InputGroup>
              </Col>
            )}
            {clase === "Matrimonio" && (
              <Col sm={12} md={6} className="mb-1 mt-1">
                <InputGroup>
                  <InputGroupText style={{ width: 200 }}>
                    Hermana
                  </InputGroupText>
                  <Form.Control
                    type="text"
                    onChange={(e) => setMadre(e.target.value)}
                    value={madre}
                  />
                </InputGroup>
              </Col>
            )}
            <Col sm={12} md={6} className="mb-1 mt-1">
              <InputGroup>
                <InputGroupText style={{ width: 200 }}>
                  Congregación
                </InputGroupText>
                <Form.Select
                  onChange={(e) => setCongregacion(e.target.value)}
                  value={congregacion}
                >
                  <option value="">Selecciona...</option>
                  {sucursales.map((data, id) => (
                    <option key={id} value={data.nombre_sucursal}>
                      {data.nombre_sucursal}
                    </option>
                  ))}
                </Form.Select>
              </InputGroup>
            </Col>
            <Col sm={12} md={6} className="mb-1 mt-1">
              <InputGroup>
                <InputGroupText style={{ width: 200 }}>Ciudad</InputGroupText>
                <Form.Control
                  type="text"
                  onChange={(e) => setCiudad(e.target.value)}
                  value={ciudad}
                />
              </InputGroup>
            </Col>
            <Col sm={12} className="mb-1 mt-1">
              <InputGroup>
                <InputGroupText
                  style={{ width: 200 }}
                  className="rounded bg-primary text-white"
                >
                  Fecha Emitido
                </InputGroupText>
                <Row style={{ width: "calc(100% - 188px)", padding: 0 }}>
                  <Col sm={4} className="mt-1 mb-1">
                    <InputGroup>
                      <InputGroupText style={{ padding: 5 }}>
                        Día
                      </InputGroupText>
                      <Form.Select
                        value={dia}
                        onChange={(e) => setDia(e.target.value)}
                      >
                        <option value="">Selecciona...</option>
                        {_.times(
                          32,
                          (i) =>
                            i > 0 && (
                              <option key={i} value={i < 10 ? "0" + i : i}>
                                {i < 10 ? "0" + i : i}
                              </option>
                            )
                        )}
                      </Form.Select>
                    </InputGroup>
                  </Col>
                  <Col sm={4} className="mt-1 mb-1">
                    <InputGroup>
                      <InputGroupText style={{ padding: 5 }}>
                        Mes
                      </InputGroupText>
                      <Form.Select
                        style={{ padding: 5 }}
                        value={mes}
                        onChange={(e) => setMes(e.target.value)}
                      >
                        <option value="">Selecciona...</option>
                        <option value="Enero">Enero</option>
                        <option value="Febrero">Febrero</option>
                        <option value="Marzo">Marzo</option>
                        <option value="Abril">Abril</option>
                        <option value="Mayo">Mayo</option>
                        <option value="Junio">Junio</option>
                        <option value="Julio">Julio</option>
                        <option value="Agosto">Agosto</option>
                        <option value="Septiembre">Septiembre</option>
                        <option value="Octubre">Octubre</option>
                        <option value="Noviembre">Noviembre</option>
                        <option value="Diciembre">Diciembre</option>
                      </Form.Select>
                    </InputGroup>
                  </Col>
                  <Col sm={4} className="mt-1 mb-1">
                    <InputGroup>
                      <InputGroupText style={{ padding: 5 }}>
                        Año
                      </InputGroupText>
                      <Form.Control
                        type="text"
                        value={anio}
                        onChange={(e) => setAnio(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </InputGroup>
            </Col>
            <Col sm={12}>
              <Card className="bg-transparent border-0 shadow-sm mt-2">
                <Card.Header className="fw-bold border-0">
                  Firmas Digitales (Opcional)
                </Card.Header>
                <Card.Body>
                  <Row>
                    {clase !== "Presentación" && (
                      <Col sm={12} md={4} className="mt-1 mb-1">
                        <Card className="bg-transparent h-100 border-0">
                          <Card.Header className="p-1 border-0 bg-transparent">
                            <Button
                              className="w-100"
                              onClick={() => {
                                fileRef1.current.click();
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faSignature}
                                className="fa-fw me-2"
                              />
                              Pastor que Ofició
                            </Button>
                          </Card.Header>
                          <Card.Body className="p-1">
                            <Image
                              src={firma1}
                              style={{
                                width: "100%",
                                height: 134,
                                objectFit: "cover",
                              }}
                            />
                            <input
                              type="file"
                              ref={fileRef1}
                              className="invisible"
                              accept="image/png"
                              value=""
                              onChange={(e) => {
                                e.preventDefault();
                                const reader1 = new FileReader();
                                let baseString1;
                                reader1.onloadend = function () {
                                  baseString1 = reader1.result;
                                  setFirma1(baseString1);
                                };
                                reader1.readAsDataURL(e.target.files[0]);
                              }}
                            />
                          </Card.Body>
                        </Card>
                      </Col>
                    )}
                    <Col sm={12} md={4} className="mt-1 mb-1">
                      <Card className="bg-transparent h-100 border-0">
                        <Card.Header className="p-1 border-0 bg-transparent">
                          <Button
                            className="w-100"
                            onClick={() => {
                              fileRef2.current.click();
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faSignature}
                              className="fa-fw me-2"
                            />
                            Pastor Local
                          </Button>
                        </Card.Header>
                        <Card.Body className="p-1">
                          <Image
                            src={firma2}
                            style={{
                              width: "100%",
                              height: 134,
                              objectFit: "cover",
                            }}
                          />
                          <input
                            type="file"
                            ref={fileRef2}
                            className="invisible"
                            accept="image/png"
                            value=""
                            onChange={(e) => {
                              e.preventDefault();
                              const reader2 = new FileReader();
                              let baseString2;
                              reader2.onloadend = function () {
                                baseString2 = reader2.result;
                                setFirma2(baseString2);
                              };
                              reader2.readAsDataURL(e.target.files[0]);
                            }}
                          />
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col sm={12} md={4} className="mt-1 mb-1">
                      <Card className="bg-transparent h-100 border-0">
                        <Card.Header className="p-1 border-0 bg-transparent">
                          <Button
                            className="w-100 rounded-top"
                            onClick={() => {
                              fileRef3.current.click();
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faSignature}
                              className="fa-fw me-2"
                            />
                            Secretario Local
                          </Button>
                        </Card.Header>
                        <Card.Body className="p-1">
                          <Image
                            src={firma3}
                            style={{
                              width: "100%",
                              height: 134,
                              objectFit: "cover",
                            }}
                          />
                          <input
                            type="file"
                            ref={fileRef3}
                            className="invisible"
                            accept="image/png"
                            value=""
                            onChange={(e) => {
                              e.preventDefault();
                              const reader3 = new FileReader();
                              let baseString3;
                              reader3.onloadend = function () {
                                baseString3 = reader3.result;
                                setFirma3(baseString3);
                              };
                              reader3.readAsDataURL(e.target.files[0]);
                            }}
                          />
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer
                  style={{
                    fontSize: 10,
                    padding: 5,
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "gray",
                    border: 0
                  }}
                >
                  Se Recomienda el Uso de Firmas Digitalizadas en Formato PNG
                  Transparente
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="border-0">
          <Button className="w-100" variant="success" onClick={(e)=>saveCertificate(e)}>
            <FontAwesomeIcon icon={faSave} className="fa-fw me-2" />
            Guardar Certificado
          </Button>
        </Card.Footer>
      </Card>
    </div>
  );
}

export default NewCert;

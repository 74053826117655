import React from "react";
import Helpers from "../../libs/Helpers";
import { Button, ButtonGroup, Card, Col, Row, Modal } from "react-bootstrap";

import { useNavigate } from "react-router-dom"; 

import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { AgGridReact } from "ag-grid-react";
import { AG_GRID_LOCALE_ES } from "@ag-grid-community/locale";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Commet } from "react-loading-indicators";

ModuleRegistry.registerModules([ClientSideRowModelModule]);
const localeText = AG_GRID_LOCALE_ES;

function OficialsList(props) {
  const [oficiales, setOficiales] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);
  const [idOficial, setIdOficial] = React.useState();

  const gridRef = React.useRef();
  const navigation = new useNavigate();

  const token = localStorage.getItem("session");

  const removeOficial = () => {
    Helpers.postAuth("/oficials/del", "POST", token, {
      id: idOficial
    }).then((data, err)=>{
      if(err) throw err
      setShowModal(false);
      gridRef.current.api.refreshCells({ params: { force: true } })
      navigation(0)
    }) 
    
    
  };

  const CustomButtonComponent = (props) => {
    return (
      <>
        <ButtonGroup>
          <Button
            variant="light"
            className="btn btn-sm"
            onClick={(e) => {
              e.preventDefault();
              setIdOficial(props.data._id);
              setShowModal(true);
            }}
          >
            <FontAwesomeIcon icon={faTrash} className="fa-fw" />
          </Button>
        </ButtonGroup>
      </>
    );
  };

  const columnDefs = [
    {
      headerName: "Nombres",
      field: "names",
      filter: true,
      floatingFilter: false,
      flex: 1,
    },
    {
      headerName: "Cargo",
      field: "cargo",
      filter: true,
      floatingFilter: false,
      flex: 1,
    },
    {
      headerName: "RUT",
      field: "rut",
      filter: true,
      floatingFilter: false,
      flex: 1,
    },
    { field: "Acciones", cellRenderer: CustomButtonComponent, flex: 1 },
  ];

  React.useEffect(() => {
    Helpers.postAuth("/oficials/all", "POST", token, {}).then((data) => {
      setOficiales(data);
      setLoading(false);
    });
  }, [token, props]);

  const defaultColDef = {
    flex: 1,
  };

  const pagination = true;
  const paginationPageSize = 10;
  const paginationPageSizeSelector = [10, 20, 30];

  return (
    <div>
      {loading && (
        <div
          style={{
            zIndex: 1000,
            width: "100vw",
            height: "100vh",
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: "rgba(255,255,255,0.5)",
            display: 'flex',
            justifyContent: "center",
            alignItems: "center",
          }}
        ><div>
          <Commet color="#32cd32" size="medium" text="" textColor="" />
          </div>
        </div>
      )}
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        animation={true}
        className="border-0"
      >
        <Modal.Header className="border-0 bg-danger text-light">
          <Modal.Title>Eliminar Oficial</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>¿Esta Seguro que Desea Eliminar este Oficial?</p>
        </Modal.Body>

        <Modal.Footer className="border-0">
          <Button
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              setShowModal(false);
            }}
          >
            Cerrar
          </Button>
          <Button
            variant="danger"
            onClick={(e) => {
              e.preventDefault();
              removeOficial();
            }}
          >
            Si, Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
      <Card className="shadow-sm border-0 bg-transparent">
        <Card.Header className="fw-bold border-0">
          <Row>
            <Col>
              <span className="fw-bold d-flex justify-content-start align-items-center h-100">
                Administración de Oficiales
              </span>
            </Col>
            <Col className="text-end">
              <Button variant="success" onClick={(e)=>{
                e.preventDefault();
                props.Vista.setOficialView(1);
              }}>
                <FontAwesomeIcon icon={faPlus} className="fa-fw me-2" />
                Nuevo Oficial
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-1">
          <div className="ag-theme-quartz" style={{ height: 550 }}>
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              rowData={oficiales}
              defaultColDef={defaultColDef}
              pagination={pagination}
              paginationPageSize={paginationPageSize}
              paginationPageSizeSelector={paginationPageSizeSelector}
              localeText={localeText}
            />
          </div>
        </Card.Body>
        <Card.Footer className="border-0"></Card.Footer>
      </Card>
    </div>
  );
}

export default OficialsList;

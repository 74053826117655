import React, { useEffect, useState, useRef } from "react";
import { toPng } from "html-to-image";
import Helpers from "../../libs/Helpers";
import QRCode from "qrcode";
import { Commet } from "react-loading-indicators";

function CompileDoc(props) {
  const token = localStorage.getItem("session");
  const [backgroundDoc, setBackgroundDoc] = useState(null);
  const [nombre_competo, setNombreCompleto] = useState();
  const [tipo_clase, setTipoClase] = useState();
  const [padre, setPadre] = useState();
  const [madre, setMadre] = useState();
  const [congregacion, setCongregacion] = useState();
  const [ciudad, setCiudad] = useState();
  const [dia, setDia] = useState();
  const [mes, setMes] = useState();
  const [anio, setAnio] = useState();
  const [firma1, setFirma1] = useState();
  const [firma2, setFirma2] = useState();
  const [firma3, setFirma3] = useState();
  const [loading, setLoading] = useState(false);
  const ref = useRef();

  const [qrCode, setQrCode] = React.useState("");
  const baseQR =
    "https://ipunsoft.ipunje.cl/verifydoc/" + props.Documento.selectId;

  useEffect(() => {
    const opts = {
      errorCorrectionLevel: "H",
      type: "image/jpeg",
      version: 7,
      quality: 1,
      margin: 4,
      color: {
        dark: "#102030FF",
        light: "#DFEFFFFF",
      },
    };
    QRCode.toDataURL(baseQR, opts).then((base64QR) => {
      setQrCode(base64QR);
    });
    Helpers.postAuth("/documents/one", "POST", token, {
      id: props.Documento.selectId,
    }).then((data) => {
      if (data) {
        setTipoClase(data.clase);
        setPadre(Helpers.Capitalize(data.padre));
        setMadre(Helpers.Capitalize(data.madre));
        setCongregacion(Helpers.Capitalize(data.congregacion));
        setCiudad(Helpers.Capitalize(data.ciudad));
        setDia(data.dia);
        setMes(data.mes);
        setAnio(data.anio);
        setFirma1(data.firma1);
        setFirma2(data.firma2);
        setFirma3(data.firma3);
        Helpers.postAuth("/layouts/all", "POST", token, {}).then((data1) => {
          if (data.tipodoc === "Certificado") {
            setNombreCompleto(Helpers.Capitalize(data.nombres_completos));
            data.clase === "Presentación"
              ? setBackgroundDoc(data1[0].layoutpresent)
              : data?.clase === "Bautismo"
              ? setBackgroundDoc(data1[0].layoutbaut)
              : data?.clase === "Matrimonio" &&
                setBackgroundDoc(data1[0].layoutmatri);
            setLoading(true);
            setTimeout(() => {
              toPng(ref.current, {
                cacheBust: true,
                quality: 1,
                skipAutoScale: false,
              })
                .then((dataUrl) => {
                  props.backBase.setBase(dataUrl);
                  props.Vista.setDocView(5);
                })
                .catch((err) => {
                  console.error(err);
                });
            }, 1000);
          }
        });
      }
    });
  }, [token, baseQR, props, ref]);

  return (
    <div>
      {loading === false && (
        <div
          style={{
            zIndex: 1000,
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "rgba(255,255,255,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          <div>
            <Commet color="#32cd32" size="medium" text="" textColor="" />
            <br />
            Compilando Certificado
          </div>
        </div>
      )}
      <div
        style={{
          width: 0,
          height: 0,
          overflow: "auto",
        }}
      >
        <div
          ref={ref}
          id="maxin"
          style={{
            width: 1169,
            height: 827,
            padding: 0,
            margin: 0,
            backgroundImage: `url("${backgroundDoc}")`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundPosition: "center",
            position: "relative",
          }}
        >
          {(tipo_clase === "Presentación" || tipo_clase === "Matrimonio") && (
            <div
              style={{
                fontSize: 40,
                zIndex: 2,
                position: "absolute",
                top: tipo_clase === "Presentación" ? 314 : 323,
                left: tipo_clase === "Presentación" ? 560 : 470,
                fontWeight: "bold",
                color: "black",
                fontFamily: "GreatVibes",
              }}
            >
              {padre}
            </div>
          )}
          {(tipo_clase === "Presentación" || tipo_clase === "Matrimonio") && (
            <div
              style={{
                fontSize: 40,
                zIndex: 2,
                position: "absolute",
                top: tipo_clase === "Presentación" ? 368 : 391,
                left: tipo_clase === "Presentación" ? 560 : 470,
                fontWeight: "bold",
                color: "black",
                fontFamily: "GreatVibes",
              }}
            >
              {madre}
            </div>
          )}
          {tipo_clase !== "Matrimonio" && (
            <div
              style={{
                fontSize: tipo_clase === "Bautismo" ? 60 : 40,
                zIndex: 2,
                position: "absolute",
                top:
                  tipo_clase === "Presentación"
                    ? 420
                    : tipo_clase === "Bautismo"
                    ? 365
                    : 0,
                left:
                  tipo_clase === "Presentación"
                    ? 600
                    : tipo_clase === "Bautismo"
                    ? 250
                    : 0,
                fontWeight: "bold",
                color: "black",
                fontFamily: "GreatVibes",
              }}
            >
              {nombre_competo}
            </div>
          )}
          <div
            style={{
              fontSize: 40,
              zIndex: 2,
              position: "absolute",
              top:
                tipo_clase === "Presentación"
                  ? 541
                  : tipo_clase === "Bautismo"
                  ? 522
                  : 518,
              left:
                tipo_clase === "Presentación"
                  ? 750
                  : tipo_clase === "Bautismo"
                  ? 700
                  : 686,
              fontWeight: "bold",
              color: "black",
              fontFamily: "GreatVibes",
            }}
          >
            {congregacion}
          </div>

          <div
            style={{
              fontSize: 40,
              zIndex: 2,
              position: "absolute",
              top:
                tipo_clase === "Presentación"
                  ? 592
                  : tipo_clase === "Bautismo"
                  ? 576
                  : 572,
              left:
                tipo_clase === "Presentación"
                  ? 190
                  : tipo_clase === "Bautismo"
                  ? 190
                  : 190,
              fontWeight: "bold",
              color: "black",
              fontFamily: "GreatVibes",
            }}
          >
            {ciudad}
          </div>

          <div
            style={{
              fontSize: 40,
              zIndex: 2,
              position: "absolute",
              top:
                tipo_clase === "Presentación"
                  ? 592
                  : tipo_clase === "Bautismo"
                  ? 576
                  : 572,
              left:
                tipo_clase === "Presentación"
                  ? 515
                  : tipo_clase === "Bautismo"
                  ? 556
                  : 510,
              fontWeight: "bold",
              color: "black",
              fontFamily: "GreatVibes",
            }}
          >
            {dia}
          </div>

          <div
            style={{
              fontSize: 40,
              zIndex: 2,
              position: "absolute",
              top:
                tipo_clase === "Presentación"
                  ? 592
                  : tipo_clase === "Bautismo"
                  ? 576
                  : 572,
              left:
                tipo_clase === "Presentación"
                  ? 720
                  : tipo_clase === "Bautismo"
                  ? 740
                  : 706,
              fontWeight: "bold",
              color: "black",
              fontFamily: "GreatVibes",
            }}
          >
            {mes}
          </div>

          <div
            style={{
              fontSize: 40,
              zIndex: 2,
              position: "absolute",
              top:
                tipo_clase === "Presentación"
                  ? 592
                  : tipo_clase === "Bautismo"
                  ? 576
                  : 572,
              left:
                tipo_clase === "Presentación"
                  ? 980
                  : tipo_clase === "Bautismo"
                  ? 1005
                  : 980,
              fontWeight: "bold",
              color: "black",
              fontFamily: "GreatVibes",
            }}
          >
            {anio}
          </div>
          {tipo_clase !== "Presentación" && (
            <div
              style={{
                zIndex: 5,
                position: "absolute",
                bottom: 90,
                left: 120,
                width: 300,
                height: 150,
                backgroundImage: `url("${firma1}")`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            />
          )}
          <div
            style={{
              zIndex: 5,
              position: "absolute",
              bottom: 90,
              left:
                tipo_clase === "Presentación"
                  ? 200
                  : tipo_clase === "Bautismo"
                  ? 430
                  : 450,
              width: 300,
              height: 150,
              backgroundImage: `url("${firma2}")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          />

          <div
            style={{
              zIndex: 5,
              position: "absolute",
              bottom: 90,
              left:
                tipo_clase === "Presentación"
                  ? 700
                  : tipo_clase === "Bautismo"
                  ? 750
                  : 750,
              width: 300,
              height: 150,
              backgroundImage: `url("${firma3}")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          />

          <div
            style={{
              zIndex: 2,
              position: "absolute",
              bottom: 20,
              right: 20,
              width: 120,
              height: 120,
              borderRadius: 10,
              backgroundImage: `url("${qrCode}")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          />
          <div
            style={{
              zIndex: 2,
              position: "absolute",
              bottom: 40,
              left: 20,
              fontSize: 10,
              fontWeight: "bold",
              color: "#102030",
              width: "auto",
            }}
          >
            REF: {props.Documento.selectId.toUpperCase()}
            <br />
            Protegido con Validación Digital
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompileDoc;

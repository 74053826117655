import React from "react";
import MainLayout from "../../layouts/MainLayout";
import FamilyList from "./FamilyList";
import FamilyView from "./FamilyView";
import FamilyAdd from "./FamilyAdd";
//import FamilyViewPDF from "./FamilyViewPDF";

function Familys() {
  let [FamilyViewScreen, setFamilyViewScreen] = React.useState(0);
  const [selectId, setSelectId] = React.useState(null);

  return (
    <div>
      <MainLayout>
        {FamilyViewScreen === 0 ? (
          <FamilyList
            Vista={{ FamilyViewScreen, setFamilyViewScreen }}
            Family={{ selectId, setSelectId }}
          />
        ) : FamilyViewScreen === 1 ? (
          <FamilyView
            Vista={{ FamilyViewScreen, setFamilyViewScreen }}
            Family={{ selectId, setSelectId }}
          />
        ) : FamilyViewScreen === 2 ? (
          <FamilyAdd
            Vista={{ FamilyViewScreen, setFamilyViewScreen }}
            Family={{ selectId, setSelectId }}
          />
        ) : (
          ""
        )}
      </MainLayout>
    </div>
  );
}

export default Familys;

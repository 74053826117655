import React from "react";
import MainLayout from "../../layouts/MainLayout";
import BranchesList from "./BranchesList";
import BranchesView from "./BranchesView";
import BranchesAdd from "./BranchesAdd";

function Branches() {
  let [BranchView, setBranchView] = React.useState(0);
  const [selectId, setSelectId] = React.useState(null);

  return (
    <div>
      <MainLayout>
        {BranchView === 0 ? (
          <BranchesList
            Vista={{ BranchView, setBranchView }}
            Sucursal={{ selectId, setSelectId }}
          />
        ) : BranchView === 1 ? (
          <BranchesView
            Vista={{ BranchView, setBranchView }}
            Sucursal={{ selectId, setSelectId }}
          />
        )  : BranchView === 2 ? (
          <BranchesAdd
            Vista={{ BranchView, setBranchView }}
            Sucursal={{ selectId, setSelectId }}
          />
        )
         : ""
        }
      </MainLayout>
    </div>
  );
}

// eslint-disable-next-line
export default Branches;

import React from "react";
import MainLayout from "../../layouts/MainLayout";
import MembersList from "./MembersList";
import MembersView from "./MembersView";
import MembersAdd from "./MembersAdd";
//import MembersViewPDF from "./MembersViewPDF";
import MemberPDF from "./MemberPDF";

function Members() {
  let [MemberView, setMemberView] = React.useState(0);
  const [selectId, setSelectId] = React.useState(null);

  return (
    <div>
      <MainLayout>
        {MemberView === 0 ? (
          <MembersList
            Vista={{ MemberView, setMemberView }}
            Member={{ selectId, setSelectId }}
          />
        ) : MemberView === 1 ? (
          <MembersView
            Vista={{ MemberView, setMemberView }}
            Member={{ selectId, setSelectId }}
          />
        ) : MemberView === 2 ? (
          <MembersAdd
            Vista={{ MemberView, setMemberView }}
            Member={{ selectId, setSelectId }}
          />
        ) : MemberView === 3 ? (
          <MemberPDF
            Vista={{ MemberView, setMemberView }}
            Member={{ selectId, setSelectId }}
          />
        ) : (
          ""
        )}
      </MainLayout>
    </div>
  );
}

export default Members;

import React from "react";
import {
  ButtonGroup,
  Button,
  Card,
  Row,
  Col,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { faFilePdf, faPlus } from "@fortawesome/free-solid-svg-icons";
import Helpers from "../../libs/Helpers";

import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { AgGridReact } from "ag-grid-react";
import { AG_GRID_LOCALE_ES } from "@ag-grid-community/locale";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
//import { PDFDownloadLink } from "@react-pdf/renderer";
//import PDFDownloader from "../../libs/PDFDownloader";
import Loader from "../../libs/Loader";

function MembersList(props) {
  const token = localStorage.getItem("session");
  ModuleRegistry.registerModules([ClientSideRowModelModule]);
  const [loading, setLoading] = React.useState(true);
  const [members, setMembers] = React.useState([]);
  const gridRef = React.useRef();

  const localeText = AG_GRID_LOCALE_ES;

  const cambiarVista = (id, vista) => {
    props.Member.setSelectId(id);
    props.Vista.setMemberView(vista);
  };

  const renderTooltipV = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Visualizar Información
    </Tooltip>
  );

  const CustomButtonComponent = (props) => {
    return (
      <>
        <ButtonGroup>
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipV}
          >
            <Button
              variant="light"
              className="btn btn-sm"
              onClick={(e) => {
                e.preventDefault();
                cambiarVista(props.data._id, 1);
              }}
            >
              <FontAwesomeIcon icon={faEye} className="fa-fw" />
            </Button>
          </OverlayTrigger>
          <Button
            variant="light"
            className="btn btn-sm"
            onClick={(e) => {
              e.preventDefault();
              cambiarVista(props.data._id, 3);
            }}
          >
            <FontAwesomeIcon icon={faFilePdf} className="fa-fw" />
          </Button>
        </ButtonGroup>
      </>
    );
  };

  /*
  <PDFDownloadLink
              document={<PDFDownloader id={props.data._id} />}
              fileName={`IPUNSOFT_Reporte_Membresia_${props.data._id}.pdf`}
            >
            </PDFDownloadLink>
  */

  const columnDefs = [
    {
      headerName: "Nombres",
      field: "names",
      filter: true,
      floatingFilter: false,
      flex: 1,
    },
    {
      headerName: "Apellidos",
      field: "lastnames",
      filter: true,
      floatingFilter: false,
      flex: 1,
    },
    {
      headerName: "Correo",
      field: "email",
      filter: true,
      floatingFilter: false,
      flex: 1,
    },
    {
      headerName: "Teléfono",
      field: "phonenumber",
      filter: true,
      floatingFilter: false,
      flex: 1,
    },
    { field: "Acciones", cellRenderer: CustomButtonComponent, flex: 1 },
  ];

  React.useEffect(() => {
    Helpers.postAuth("/members/all", "POST", token, {}).then((data) => {
      setMembers(data);
      setLoading(false);
    });
  }, [token]);

  const defaultColDef = {
    flex: 1,
  };

  const pagination = true;
  const paginationPageSize = 10;
  const paginationPageSizeSelector = [10, 20, 30];

  return (
    <div>
      {loading && <Loader />}
      <Card className="border-0 bg-transparent shadow-sm">
        <Card.Header className="border-0">
          <Row>
            <Col>
              <span className="fw-bold d-flex justify-content-start align-items-center h-100">
                Gestión de Membresía
              </span>
            </Col>
            <Col className="text-end">
              <Button
                variant="success"
                onClick={(e) => {
                  e.preventDefault();
                  props.Vista.setMemberView(2);
                }}
              >
                <FontAwesomeIcon icon={faPlus} className="fa-fw me-2" />
                Nuevo Registro
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-1">
          <div
            className="ag-theme-quartz border-0 rounded-0"
            style={{ height: 550 }}
          >
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              rowData={members}
              defaultColDef={defaultColDef}
              pagination={pagination}
              paginationPageSize={paginationPageSize}
              paginationPageSizeSelector={paginationPageSizeSelector}
              localeText={localeText}
              className="rounded-0"
            />
          </div>
        </Card.Body>
        <Card.Footer className="border-0"></Card.Footer>
      </Card>
    </div>
  );
}

// eslint-disable-next-line
export default MembersList;

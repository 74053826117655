import React from "react";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import DocsPDF from "../../libs/DocsPDF";
import { Card, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faDownload } from "@fortawesome/free-solid-svg-icons";
import { saveAs } from "file-saver";
function DocsViewPDF(props) {

  const renderTooltipV = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Descargar en PDF
    </Tooltip>
  );

  const NewPDF = () => {
    return (
      <DocsPDF
        id={props.Documento.selectId}
        file={`IPUNSOFT_Documento_${props.Documento.selectId}`}
        back={props.backBase.base}
      />
    );
  };

  const saveFile = () => {
    pdf(<NewPDF />)
      .toBlob()
      .then((blob) =>
        saveAs(blob, `IPUNSOFT_Documento_${props.Documento.selectId}`)
      );
  };

  return (
    <>
      <Card className="shadow-sm border-0 bg-transparent h-100">
        <Card.Header
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            onClick={(e) => {
              e.preventDefault();
              props.Vista.setDocView(0);
            }}
            variant="transparent"
            className="fw-bold"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="fa-fw me-2" />
            Visor de Documentos
          </Button>
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipV}
          >
            <Button
              variant="dark"
              onClick={(e) => {
                e.preventDefault();
                saveFile();
              }}
            >
              <FontAwesomeIcon icon={faDownload} className="fa-fw" />
            </Button>
          </OverlayTrigger>
        </Card.Header>
        <Card.Body className="p-1">
          <PDFViewer
            width={"100%"}
            height={"100%"}
            className="rounded"
            showToolbar={false}
          >
            <NewPDF />
          </PDFViewer>
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
    </>
  );
}

export default DocsViewPDF;

import React, { useState } from "react";
import MainLayout from "../layouts/MainLayout";
import {
  Card,
  Col,
  Row,
  Image,
  InputGroup,
  Form,
  Button,
  Collapse,
  Alert,
} from "react-bootstrap";
import Helpers from "../libs/Helpers";
import { Commet } from "react-loading-indicators";
import { decodeToken } from "react-jwt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faKey, faShield } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Firma from "../componentes/Firma";
import InputGroupText from "react-bootstrap/esm/InputGroupText";

function Profile() {
  const decode = decodeToken(localStorage.getItem("session"));
  const token = localStorage.getItem("session");
  const navigation = new useNavigate();

  const [loading, setLoading] = React.useState(false);
  const [id, setID] = React.useState();
  const [nombres, setNombres] = React.useState();
  const [apellidos, setApellidos] = React.useState();
  const [correo, setCorreo] = React.useState();
  const [telefono, setTelefono] = React.useState();
  const [licencia, setLicencia] = React.useState();
  const [rut, setRut] = React.useState();
  const [foto, setFoto] = React.useState();
  const [cargo, setCargo] = useState();
  const [congregation, setCongregation] = useState();
  const [direction, setDirection] = useState();
  const [roll, setRoll] = useState();
  const [secret, setSecret] = React.useState();
  const [newSecret, setNewSecret] = React.useState();
  const [hostname, setHostname] = React.useState();

  const [alertOpen, setAlertOpen] = React.useState(false);
  const [textAlert, setTextAlert] = React.useState();
  const [variantAlert, setVariantAlert] = React.useState("warning");
  const [ronly, setRonly] = React.useState(true);
  const [firma, setFirma] = useState();

  const getAddress = (nombre_sucursal) => {
    return new Promise((response, reject) => {
      Helpers.postAuth(
        "/branches/onename",
        "POST",
        localStorage.getItem("session"),
        {
          nombre_sucursal,
        }
      )
        .then((data) => {
          response(data?.direccion);
        })
        .catch((err) => console.log(err));
    });
  };

  React.useEffect(() => {
    
    if (loading === false) {
      console.log(process.env);
      Helpers.postAuth("/admins/one", "POST", token, {
        id: decode?.v1.id,
      }).then(async (data) => {
        setID(data?._id);
        setNombres(data?.names);
        setApellidos(data?.lastnames);
        setCorreo(data?.email);
        setTelefono(data?.phonenumber);
        setLicencia(data?.licencia);
        setRut(data?.rut);
        setFoto(data?.imagen64);
        setRoll(data?.roll);
        setCongregation(data?.congregation);
        setFirma(data?.firma);
        setLoading(true);
      });
    }
  }, [loading, token, decode]);

  React.useEffect(() => {
    Helpers.getRoll(roll).then((carga) => setCargo(carga));
  }, [roll]);

  React.useEffect(() => {
    getAddress(congregation).then((direction) => setDirection(direction));
  }, [congregation]);

  const showAlert = (texto) => {
    setTextAlert(texto);
    setAlertOpen(true);
    setTimeout(() => {
      setAlertOpen(false);
    }, 3000);
  };

  const changePassword = () => {
    if (!secret || !newSecret) {
      setVariantAlert("warning");
      showAlert("Debes Ingresar la Información");
    } else if (secret !== newSecret) {
      setVariantAlert("warning");
      showAlert("Las Contraseñas no Coinciden");
    } else {
      Helpers.postAuth("/admins/updp", "POST", token, {
        id,
        secret,
      }).then((data, err) => {
        if (err) {
          setVariantAlert("danger");
          showAlert("Error al cambiar la contraseña");
        } else {
          setVariantAlert("info");
          showAlert("Actualización Completada");
          localStorage.removeItem("session");
          setTimeout(() => {
            navigation("/");
          }, 3000);
        }
      });
    }
  };

  const saveFirma = (signf) => {
    Helpers.postAuth("/admins/updf", "POST", token, {
      id,
      firma: signf,
    }).then((data, err) => {
      if (err) {
        setVariantAlert("danger");
        showAlert("Error al cambiar la Firma");
      } else {
        setVariantAlert("info");
        showAlert("Actualización Completada");
        setRonly(true);
        /*setTimeout(() => {
          navigation(0);
        }, 3000);*/
      }
    });
  };

  return (
    <MainLayout>
      <Collapse
        in={alertOpen}
        dimension={"height"}
        timeout={300}
        className="fixed-top p-3 m-2 rounded"
      >
        <Alert
          variant={variantAlert}
          className="fw-bold text-center"
          style={{
            display: alertOpen ? "block" : "none",
          }}
          id="example-collapse-text"
        >
          {textAlert}
        </Alert>
      </Collapse>
      {loading === false ? (
        <div
          style={{
            zIndex: 1000,
            width: "100vw",
            height: "100vh",
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "rgba(255,255,255,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <Commet color="#32cd32" size="medium" text="" textColor="" />
          </div>
        </div>
      ) : (
      <Card className="shadow-sm border-0 bg-transparent">
        <Card.Header className="fw-bold border-0">
          Información de mi Perfíl {hostname}
        </Card.Header>
        <Card.Body>
          <Row>
            <Col sm={12} md={3} lg={2} style={{ justifyContent: "center" }}>
              <Image
                width="100%"
                height="auto"
                src={foto}
                style={{ borderRadius: 10 }}
              />
            </Col>
            <Col>
              <Card className="shadow-sm border-0 bg-transparent">
                <Card.Body>
                  <Row>
                    <Col sm={12} md={6} className="mb-2">
                      <InputGroup>
                        <InputGroup.Text className="colLeft">
                          Nombres
                        </InputGroup.Text>
                        <Form.Control type="text" value={nombres} readOnly />
                      </InputGroup>
                    </Col>
                    <Col sm={12} md={6} className="mb-2">
                      <InputGroup>
                        <InputGroup.Text className="colLeft">
                          Apellidos
                        </InputGroup.Text>
                        <Form.Control type="text" value={apellidos} readOnly />
                      </InputGroup>
                    </Col>
                    <Col sm={12} md={6} className="mb-2">
                      <InputGroup>
                        <InputGroup.Text className="colLeft">
                          RUT
                        </InputGroup.Text>
                        <Form.Control type="text" value={rut} readOnly />
                      </InputGroup>
                    </Col>
                    <Col sm={12} md={6} className="mb-2">
                      <InputGroup>
                        <InputGroup.Text className="colLeft">
                          Correo
                        </InputGroup.Text>
                        <Form.Control type="text" value={correo} readOnly />
                      </InputGroup>
                    </Col>
                    <Col sm={12} md={6} className="mb-2">
                      <InputGroup>
                        <InputGroup.Text className="colLeft">
                          Teléfono
                        </InputGroup.Text>
                        <Form.Control type="text" value={telefono} readOnly />
                      </InputGroup>
                    </Col>
                    <Col sm={12} md={6} className="mb-2">
                      <InputGroup>
                        <InputGroup.Text className="colLeft">
                          Licencia
                        </InputGroup.Text>
                        <Form.Control type="text" value={licencia} readOnly />
                      </InputGroup>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className="border-0">
                  <Card className="shadow-sm border-0 bg-transparent">
                    <Card.Header className="fw-bold border-0">
                      Cambiar Contraseña
                    </Card.Header>
                    <Card.Body>
                      <form onSubmit={(e) => e.preventDefault()}>
                        <Row>
                          <Col sm={12} md={5} className="mb-2">
                            <InputGroup>
                              <InputGroup.Text>
                                <FontAwesomeIcon
                                  icon={faKey}
                                  className="fa-fw"
                                />
                              </InputGroup.Text>
                              <Form.Control
                                type="password"
                                value={secret}
                                autoComplete="new-password"
                                onChange={(e) => setSecret(e.target.value)}
                                placeholder="Ingresa Contraseña"
                              />
                            </InputGroup>
                          </Col>
                          <Col sm={12} md={5} className="mb-2">
                            <InputGroup>
                              <InputGroup.Text>
                                <FontAwesomeIcon
                                  icon={faShield}
                                  className="fa-fw"
                                />
                              </InputGroup.Text>
                              <Form.Control
                                type="password"
                                value={newSecret}
                                autoComplete="new-password"
                                onChange={(e) => setNewSecret(e.target.value)}
                                placeholder="Confirma Contraseña"
                              />
                            </InputGroup>
                          </Col>
                          <Col sm={12} md={2} className="mb-2">
                            <Button
                              variant="success"
                              className="w-100"
                              onClick={(e) => {
                                e.preventDefault();
                                changePassword();
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="fa-fw"
                              />
                            </Button>
                          </Col>
                        </Row>
                      </form>
                    </Card.Body>
                  </Card>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="border-0">
          <Card className="bg-transparent border-0 shadow-sm">
            <Card.Header className="fw-bold border-0">
              Información Documental
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm={6} className="mt-1 mb-1">
                  <Card className="bg-transparent border-0 shadow-sm">
                    <Card.Header className="border-0">
                      Datos Administrativos
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col sm={12} className="mb-2">
                          <InputGroup>
                            <InputGroupText style={{ width: 100 }}>
                              Cargo
                            </InputGroupText>
                            <Form.Control type="text" readOnly value={cargo} />
                          </InputGroup>
                        </Col>
                        <Col sm={12} className="mb-2">
                          <InputGroup>
                            <InputGroupText style={{ width: 100 }}>
                              Sede
                            </InputGroupText>
                            <Form.Control
                              type="text"
                              readOnly
                              value={congregation}
                            />
                          </InputGroup>
                        </Col>
                        <Col sm={12} className="mb-2">
                          <InputGroup>
                            <InputGroupText style={{ width: 100 }}>
                              Dirección
                            </InputGroupText>
                            <Form.Control
                              type="text"
                              readOnly
                              value={direction}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={6} className="mt-1 mb-1">
                  <Card className="border-0">
                    <Card.Body className="p-0 mt-0">
                      <Firma dataSign={{ firma, setFirma }} disable={ronly} />
                    </Card.Body>
                    <Card.Footer className="bg-info">
                      <Button
                        variant="dark"
                        onClick={(e) => {
                          e.preventDefault();
                          if (ronly === false) {
                            saveFirma(firma);
                          } else {
                            setRonly(false);
                          }
                        }}
                      >
                        {ronly ? "Editar Firma" : "Guardar Firma"}
                      </Button>
                    </Card.Footer>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Card.Footer>
      </Card>)}
    </MainLayout>
  );
}

export default Profile;

import React from "react";
import MainLayout from "../../layouts/MainLayout";

function Opening() {
  return (
    <div>
      <MainLayout>
        Cierre y Apertura
      </MainLayout>
    </div>
  );
}

export default Opening;

import React from "react";
import {
  Font,
  Text,
  Image,
  View,
  Page,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import LogoPlano from "../assets/Images/Logo_plano.png";
import logoAdmin from "../assets/Images/Ipunsoft.png";
import MyriadPro from "../assets/Fonts/MyriadPro.otf";
import Helpers from "./Helpers";
import QRCode from "qrcode";


Font.register({
  family: "MyriadPro",
  src: MyriadPro,
});

const PDFDownloader = (props) => {
  const [qrCode, setQrCode] = React.useState("");
  const baseQR = "https://ipunsoft.ipunje.cl/verifymember/" + props.id;

  const token = localStorage.getItem("session");
  const [names, setNames] = React.useState();
  const [lastnames, setLastNames] = React.useState();
  const [typedoc, setTypeDoc] = React.useState();
  const [numdoc, setNumDoc] = React.useState();
  const [nationality, setNationality] = React.useState();
  const [placeofbirth, setPlaceOfBirth] = React.useState();
  const [dateofbirth, setDateOfBirth] = React.useState();
  const [gender, setGender] = React.useState();
  const [groupFamily, setGroupFamily] = React.useState();
  const [address, setAddress] = React.useState();
  const [phonenumber, setPhoneNumber] = React.useState();
  const [civilstatus, setCivilStatus] = React.useState();
  const [placefamily, setPlaceFamily] = React.useState();
  const [scholarship, setScholarShip] = React.useState();
  const [congregation, setCongregation] = React.useState();
  const [occupation, setOccupation] = React.useState();
  const [datewaterbaptism, setDateWaterBaptism] = React.useState();
  const [citybaptism, setCityBaptism] = React.useState();
  const [shepherdbaptism, setShepherdBaptism] = React.useState();
  const [holyspirit, setHolySpirit] = React.useState();
  const [positionsheld, setPositionSheld] = React.useState();
  const [howmeetlord, setHowMeetLord] = React.useState();
  const [addnote, setAddNote] = React.useState();
  const [photo, setPhoto] = React.useState();
  const [email, setEmail] = React.useState();
  const [firma, setFirma] = React.useState();

  

  React.useEffect(() => {
    Helpers.postAuth("/members/one", "POST", token, {
      id: props.id,
    })
      .then((data) => {
        setNames(data.names);
        setLastNames(data.lastnames);
        setTypeDoc(data.typedoc.toUpperCase());
        setNumDoc(data.numdoc);
        setNationality(data.nationality);
        setPlaceOfBirth(data.placeofbirth);
        setDateOfBirth(data.dateofbirth);
        setGender(data.gender);
        setGroupFamily(data.group_family);
        setAddress(data.address);
        setPhoneNumber(data.phonenumber);
        setCivilStatus(data.civilstatus);
        setPlaceFamily(data.placefamily);
        setScholarShip(data.scholarship);
        setCongregation(data.congregation);
        setOccupation(data.occupation);
        setDateWaterBaptism(data.datewaterbaptism);
        setCityBaptism(data.citybaptism);
        setShepherdBaptism(data.shepherdbaptism);
        setHolySpirit(data.holyspirit);
        setPositionSheld(data.positionsheld);
        setHowMeetLord(data.howmeetlord);
        setAddNote(data.addnote);
        setFirma(data.firma);
        setPhoto(data.photo);
        setEmail(data.email);
      })
      .finally(() => {
        props.estado.setLoading(true);
      });
    const opts = {
      errorCorrectionLevel: "H",
      type: "image/jpeg",
      version: 7,
      quality: 1,
      margin: 4,
      color: {
        dark: "#102030FF",
        light: "#DFEFFFFF",
      },
    };
    QRCode.toDataURL(baseQR, opts).then((base64QR) => {
      setQrCode(base64QR);
    });
  }, [token, props, baseQR]);

  const myDocument = React.useRef();
  //  const { qr } = props

  const styles = StyleSheet.create({
    page: {
      fontSize: 11,
      fontFamily: "MyriadPro",
      paddingTop: 20,
      paddingLeft: 40,
      paddingRight: 40,
      lineHeight: 1.5,
      flexDirection: "column",
    },
    spaceBetween: {
      width: "100%",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      color: "#3E3E3E",
    },
    normalAlign: {
      width: "100%",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },

    rightAlign: {
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "flex-end",
      height: 30,
    },

    titleContainer: { flexDirection: "row", marginTop: 0 },
    logo: { width: 100 },
    logoAdm: { width: 160 },
    reportTitle: {
      fontSize: 9,
      color: "#004499",
      fontFamily: "MyriadPro",
      textAlign: "center",
      height: 22,
      width: 200,
      paddingTop: 5,
      paddingLeft: 5,
      paddingRight: 5,
      backgroundColor: "#CDE",
      border: "1px solid #BCD",
      borderRadius: 5,
      fontWeight: "bold",
      marginBottom: 10,
    },
    row: {
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "5px",
    },
    column: {
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "column",
      width: "auto",
    },
    inputLeft: {
      width: "30%",
      height: 22,
      paddingTop: "5px",
      paddingLeft: "5px",
      backgroundColor: "#CDE",
      fontWeight: "bold",
      fontFamily: "MyriadPro",
      textAlign: "left",
      borderLeft: "1px solid #BCD",
      borderTop: "1px solid #BCD",
      borderBottom: "1px solid #BCD",
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      fontSize: 9,
    },
    inputRight: {
      width: "70%",
      height: 22,
      paddingTop: "5px",
      paddingLeft: "5px",
      backgroundColor: "rgba(245,250,255,0)",
      textAlign: "left",
      fontFamily: "MyriadPro",
      border: "1px solid #BCD",
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
      fontSize: 9,
    },
    cardHeader: {
      paddingTop: 5,
      paddingLeft: 5,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      border: "1px solid #BCD",
      backgroundColor: "#CDE",
      width: "100%",
      fontSize: 10,
      fontFamily: "MyriadPro",
      fontWeight: "bold",
    },
    cardBody: {
      border: "1px solid #BCD",
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
      padding: 5,
      width: "100%",
      height: "auto",
      textAlign: "justify",
      fontFamily: "MyriadPro",
      marginBottom: 4,
    },
    badged: {
      width: "100%",
      height: 24,
      paddingTop: "5px",
      backgroundColor: "#CDE",
      paddingLeft: "5px",
      borderRadius: 5,
      border: "1px solid #BCD",
      marginBottom: 5,
      marginTop: 10,
    },
    imaginamax: {
      width: 120,
      height: 120,
      objectFit: "cover",
      borderRadius: 10,
    },
    imagina: {
      width: 120,
      height: 120,
      display: "block",
      objectFit: "cover",
      color: "blue",
      padding: 5,
      borderRadius: 5,
    },
    italic: { fontSize: 10, textAlign: "center" },
    upper: { textTransform: "uppercase", color: "#0166AA", fontWeight: "bold" },
    footer: {
      position: "absolute",
      bottom: 20,
      left: 20,
      right: 20,
      fontSize: 10,
      textAlign: "center",
      color: "#888",
      fontFamily: "MyriadPro",
    },
    firma: {
      width: 120,
      height: 60,
    },
  });

  const Footer = () => {
    return (
      <View style={styles.footer}>
        <hr
          style={{
            borderTop: "1px solid #AAA",
            marginBottom: 5,
          }}
        />
        <Text>
          Este documento fue elaborado por IPUNSOFT, Administración y Finanzas
        </Text>
      </View>
    );
  };

  const DocumentTitle = () => {
    return (
      <>
        <View style={styles.titleContainer}>
          <View style={styles.spaceBetween}>
            <Image src={LogoPlano} style={styles.logo} />
            <Image src={logoAdmin} style={styles.logoAdm} />
          </View>
        </View>
        <View style={styles.rightAlign}>
          <Text style={styles.reportTitle}>
            Membresía: {props.id.toUpperCase()}
          </Text>
        </View>
      </>
    );
  };

  const DocumentPhoto = () => {
    return (
      <>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          {photo && (
            <Image
              style={[
                styles.imaginamax,
                {
                  marginRight: 5,
                },
              ]}
              src={photo}
            />
          )}
          <View style={styles.column}>
            <View style={styles.row}>
              <Text style={styles.inputLeft}>Nombres</Text>
              <Text style={styles.inputRight}>{names}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.inputLeft}>Apellidos</Text>
              <Text style={styles.inputRight}>{lastnames}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.inputLeft}>Documento</Text>
              <Text style={styles.inputRight}>
                {typedoc}: {numdoc}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.inputLeft}>Ciudad Nacimiento</Text>
              <Text style={styles.inputRight}>{placeofbirth}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.inputLeft}>Nacionalidad</Text>
              <Text style={styles.inputRight}>{nationality}</Text>
            </View>
          </View>
        </View>
        <View style={[styles.column, { marginTop: 5 }]}>
          <View style={styles.row}>
            <Text style={styles.inputLeft}>Nacimiento</Text>
            <Text style={styles.inputRight}>{dateofbirth}</Text>
            <Text style={[styles.inputLeft, { marginLeft: 5 }]}>Género</Text>
            <Text style={styles.inputRight}>{gender}</Text>
          </View>
        </View>
        <View style={styles.column}>
          <View style={styles.row}>
            <Text style={styles.inputLeft}>Teléfono</Text>
            <Text style={styles.inputRight}>{phonenumber}</Text>
            <Text style={[styles.inputLeft, { marginLeft: 5 }]}>Email</Text>
            <Text style={styles.inputRight}>{email}</Text>
          </View>
        </View>
        <View style={styles.column}>
          <View style={styles.row}>
            <Text style={styles.inputLeft}>Estado Civil</Text>
            <Text style={styles.inputRight}>{civilstatus}</Text>
            <Text style={[styles.inputLeft, { marginLeft: 5 }]}>
              Grupo Familiar
            </Text>
            <Text style={styles.inputRight}>{groupFamily}</Text>
          </View>
        </View>
        <View style={styles.column}>
          <View style={styles.row}>
            <Text style={styles.inputLeft}>Nivel Educación</Text>
            <Text style={styles.inputRight}>{scholarship}</Text>
            <Text style={[styles.inputLeft, { marginLeft: 5 }]}>Ocupación</Text>
            <Text style={styles.inputRight}>{occupation}</Text>
          </View>
        </View>
        <View style={styles.column}>
          <View style={styles.row}>
            <Text style={[styles.inputLeft]}>Dirección</Text>
            <Text style={styles.inputRight}>{address}</Text>
          </View>
        </View>
        <View style={styles.badged}>
          <Text style={{ fontSize: 10, fontWeight: "bold" }}>
            Información Eclesiástica
          </Text>
        </View>
        <View style={styles.column}>
          <View style={styles.row}>
            <Text style={styles.inputLeft}>Fecha Bautismo</Text>
            <Text style={styles.inputRight}>{datewaterbaptism}</Text>
            <Text style={[styles.inputLeft, { marginLeft: 5 }]}>
              Lugar Bautismo
            </Text>
            <Text style={styles.inputRight}>{citybaptism}</Text>
          </View>
        </View>
        <View style={styles.column}>
          <View style={styles.row}>
            <Text style={styles.inputLeft}>Pastor Oficiante</Text>
            <Text style={styles.inputRight}>{shepherdbaptism}</Text>
            <Text style={[styles.inputLeft, { marginLeft: 5 }]}>
              Recepción E/S
            </Text>
            <Text style={styles.inputRight}>{holyspirit}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <Text style={styles.inputLeft}>Congregación Actual</Text>
          <Text style={styles.inputRight}>{congregation}</Text>
        </View>
      </>
    );
  };

  const PageTwo = () => {
    return (
      <>
        <View style={styles.column}>
          <View style={styles.cardHeader}>
            <Text>Como conoció al Señor</Text>
          </View>
          <View style={styles.cardBody}>
            <Text>{howmeetlord}</Text>
          </View>
        </View>
        <View style={styles.column}>
          <View style={styles.cardHeader}>
            <Text>Cargos Desempeñados</Text>
          </View>
          <View style={styles.cardBody}>
            <Text>{positionsheld}</Text>
          </View>
        </View>
        <View style={styles.column}>
          <View style={styles.cardHeader}>
            <Text>Notas Eventuales</Text>
          </View>
          <View style={styles.cardBody}>
            <Text>{addnote}</Text>
          </View>
        </View>
        <View style={[styles.row, { marginTop: 15 }]}>
          <View style={{ width: "70%" }}>
            <Text style={[styles.italic, styles.upper]}>
              Este Documento tiene validación digital, escanee el código QR
              desde su dispositivo móvil, para obtener la versión online de
              verificación, una vez abierto, compare la información.
            </Text>
          </View>
          <View
            style={{
              width: "30%",
              padding: 0,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              style={{
                padding: 5,
                border: "2px solid #FFF",
                borderRadius: 5,
                width: "110px",
                height: "110px",
                justifyContent: "center",
                alignItems: "center",
              }}
              src={qrCode}
            />
          </View>
        </View>
        <View style={[styles.row, { marginTop: 15 }]}>
          <View
            style={{
              width: 360,
              height: 100,
              padding: 0,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={[styles.italic, styles.upper]}>Firma del Creyente:</Text>
            {firma && <Image src={firma} width="360" height="67" />}
          </View>
        </View>
      </>
    );
  };

  const Documento = () => {
    return (
      <Document ref={myDocument} file={props.file} title={props.file}>
        <Page
          size="Letter"
          style={styles.page}
          pageNumber={1}
          renderMode={"canvas"}
        >
          <DocumentTitle />
          <DocumentPhoto />
          <Footer />
        </Page>
        <Page
          size="Letter"
          style={styles.page}
          pageNumber={2}
          renderMode={"canvas"}
        >
          <PageTwo />
          <Footer />
        </Page>
      </Document>
    );
  };

  return <>{<Documento />}</>;
};

export default PDFDownloader;

import React from "react";
import Background from "../assets/Images/background2.jpg";
import { useParams } from "react-router";
import { decodeToken, isExpired } from "react-jwt";
import logoAdmin from "../assets/Images/Ipunsoft.png";
import {
  Button,
  Card,
  Form,
  Image,
  InputGroup,
  Collapse,
  Alert,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faRefresh, faShield } from "@fortawesome/free-solid-svg-icons";
import Helpers from "../libs/Helpers";
import { useNavigate } from "react-router-dom";

function Forgot(props) {
  const { token } = useParams();
  const usuario = decodeToken(token);
  const activo = isExpired(token);
  const navigation = new useNavigate();

  const [alertOpen, setAlertOpen] = React.useState(false);
  const [textAlert, setTextAlert] = React.useState();
  const [variantAlert, setVariantAlert] = React.useState("warning");

  const [secret, setSecret] = React.useState();
  const [newSecret, setNewSecret] = React.useState();

  console.log(activo);
  // <!-- Forgot {usuario.v1.id} {activo} -->

  const showAlert = (texto) => {
    setTextAlert(texto);
    setAlertOpen(true);
    setTimeout(() => {
      setAlertOpen(false);
    }, 3000);
  };

  const changePassword = () => {
    if (!secret || !newSecret) {
      setVariantAlert("warning");
      showAlert("Debes Ingresar la Información");
    } else if (secret !== newSecret) {
      setVariantAlert("warning");
      showAlert("Las Contraseñas no Coinciden");
    } else {
      Helpers.queryNoAuth("/admins/updp", "POST", {
        id: usuario.v1.id,
        secret,
      }).then((data, err) => {
        if (err) {
          setVariantAlert("danger");
          showAlert("Error al cambiar la contraseña");
        } else {
          setVariantAlert("info");
          showAlert("Actualización Completada");
          localStorage.removeItem("session");
          setTimeout(() => {
            navigation("/");
          }, 3000);
        }
      });
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${Background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
      }}
    >
      <Collapse
        in={alertOpen}
        dimension={"height"}
        timeout={300}
        className="fixed-top p-3 m-2 rounded"
      >
        <Alert
          variant={variantAlert}
          className="fw-bold text-center"
          style={{
            display: alertOpen ? "block" : "none",
          }}
          id="example-collapse-text"
        >
          {textAlert}
        </Alert>
      </Collapse>

      {!activo ? (
        <div>
          <Image
            src={logoAdmin}
            style={{
              width: 340,
              height: 90,
              marginBottom: 20,
            }}
          />
          <Card className="border-0 bg-transparent">
            <Card.Header className="text-white fw-bold text-center text-uppercase">
              Resetear Contraseña
            </Card.Header>
            <Card.Body>
              <InputGroup className="mb-2">
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faKey} className="fa-fw" />
                </InputGroup.Text>
                <Form.Control
                  type="password"
                  value={secret}
                  onChange={(e) => setSecret(e.target.value)}
                  placeholder="Ingrese Contraseña"
                />
              </InputGroup>
              <InputGroup className="mb-2">
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faShield} className="fa-fw" />
                </InputGroup.Text>
                <Form.Control
                  type="password"
                  value={newSecret}
                  onChange={(e) => setNewSecret(e.target.value)}
                  placeholder="Confirme Contraseña"
                />
              </InputGroup>
              <Button
                className="w-100"
                onClick={(e) => {
                  e.preventDefault();
                  changePassword();
                }}
              >
                Cambiar Contraseña
              </Button>
            </Card.Body>
          </Card>
        </div>
      ) : (
        <div>
          <Image
            src={logoAdmin}
            style={{
              width: 250,
              height: 70,
            }}
          />
          <hr />
          <h2>Este enlace ya Expiró</h2>
          <Button className="w-100 mt-3" onClick={(e)=>{
            e.preventDefault();
            navigation("/")
          }}><FontAwesomeIcon icon={faRefresh} className="fa-fw me-2" />Intentar Nuevamente</Button>
        </div>
      )}
    </div>
  );
}

export default Forgot;

import {
  faMicrophoneAlt,
  faMicrophoneAltSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, { useEffect, useState } from "react";
import { Collapse, Alert } from "react-bootstrap";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

const Dictaphone = (props) => {
  const [transcript, setTranscript] = useState("");
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [textAlert, setTextAlert] = React.useState();
  const [variantAlert, setVariantAlert] = React.useState("warning");

  const showAlert = (texto) => {
    setTextAlert(texto);
    setAlertOpen(true);
    setTimeout(() => {
      setAlertOpen(false);
    }, 3000);
  };

  const comands = [
    {
      command: ['Borrar', 'Limpiar'],
      callback: ({ resetTranscript }) => { resetTranscript() },
      isFuzzyMatch: true,
      fuzzyMatchingThreshold: 0.2,
      bestMatchOnly: true
    },
  ];

  const {
    transcript: transcriptFromSpeechRecognition,
    listening,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition({ comands });

  useEffect(() => {
    try {
      if (transcriptFromSpeechRecognition !== transcript) {
        setTranscript(transcriptFromSpeechRecognition);

        if (props.foco.current) {
          const words = transcriptFromSpeechRecognition.split(" ");
          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }
          props.onChange(words.join(" "));
        } else {
          showAlert(
            "La IA de Dictado, Requiere que Selecciones un Cuadro de Texto"
          );
        }
      }
    } catch {}
  }, [transcriptFromSpeechRecognition, transcript, props]);

  const handleStart = () => {
    SpeechRecognition.startListening({ continuous: false, language: "es-US" });
  };

  const handleStop = () => {
    SpeechRecognition.stopListening();
  };

  if (!browserSupportsSpeechRecognition) {
    return null;
  }

  return (
    <div>
      <Collapse
        in={alertOpen}
        dimension={"height"}
        timeout={300}
        className="fixed-top p-3 m-2 rounded"
      >
        <Alert
          variant={variantAlert}
          className="fw-bold text-center"
          style={{
            display: alertOpen ? "block" : "none",
          }}
          id="example-collapse-text"
        >
          {textAlert}
        </Alert>
      </Collapse>
      {listening === true ? (
        <div
          className="border-0 m-0"
          style={{
            cursor: "pointer",
            width: 40,
            height: 30,
            padding: 0,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
          onClick={handleStop}
        >
          <FontAwesomeIcon
            icon={faMicrophoneAltSlash}
            className="fa-fw fa-1x"
            color="red"
          />
        </div>
      ) : (
        <div
          style={{
            cursor: "pointer",
            width: 40,
            height: 20,
            padding: 0,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
          onClick={handleStart}
        >
          <FontAwesomeIcon
            icon={faMicrophoneAlt}
            className="fa-fw fa-1x w-100"
            color="green"
          />
        </div>
      )}
    </div>
  );
};
export default Dictaphone;

import React from "react";
import { Card, Button, Row, Col, InputGroup, Form, Collapse, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import Helpers from "../../libs/Helpers";

function DocsAdd(props) {

  const token = localStorage.getItem("session");

  const [roll, setRoll] = React.useState();
  const [description, setDescription] = React.useState();

  const [alertOpen, setAlertOpen] = React.useState(false);
  const [textAlert, setTextAlert] = React.useState();
  const [variantAlert, setVariantAlert] = React.useState("warning");

  const showAlert = (texto) => {
    setTextAlert(texto);
    setAlertOpen(true);
    setTimeout(() => {
      setAlertOpen(false);
    }, 3000);
  };

  const addRoll = () => {
    if(!roll || !description){
      setVariantAlert("warning")
      showAlert("Debes Ingresar todos los Datos Requeridos")
    } else {
      Helpers.postAuth("/roles/add", "POST", token, {
        roll, description
      }).then((data,err)=>{
        if(err) throw err
        setVariantAlert("info")
        showAlert("Roll Creado con Exito")
        setTimeout(() => {
          props.Vista.setRollView(0);
        }, 3000);
      })
    }
  }

  return (
    <div>
      <Collapse
        in={alertOpen}
        dimension={"height"}
        timeout={300}
        className="fixed-top p-3 m-2 rounded"
      >
        <Alert
          variant={variantAlert}
          className="fw-bold text-center"
          style={{
            display: alertOpen ? "block" : "none",
          }}
          id="example-collapse-text"
        >
          {textAlert}
        </Alert>
      </Collapse>
      <Card className="shadow-sm border-0 bg-transparent">
        <Card.Header>
          <Button
            onClick={(e) => {
              e.preventDefault();
              props.Vista.setRollView(0);
            }}
            variant="transparent"
            className="fw-bold"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="fa-fw me-2" />
            Agregar Nuevo Roll
          </Button>
        </Card.Header>
        <Card.Body>
          <form onSubmit={(e) => e.preventDefault()}>
            <Row>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeft">
                    Roll
                  </InputGroup.Text>
                  <Form.Control type="text" required value={roll} onChange={(e)=>{
                    setRoll(e.target.value)
                  }} />
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
              <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeft">
                    Descripción
                  </InputGroup.Text>
                  <Form.Control type="text" required value={description} onChange={(e)=>{
                    setDescription(e.target.value)
                  }} />
                </InputGroup>
              </Col>
              <Col sm={12} className="mt-4">
                <Button className="w-100" onClick={(e)=>{
                  e.preventDefault();
                  addRoll();
                }}><FontAwesomeIcon icon={faSave} className="fa-fw me-2" /> Guardar Roll</Button>
              </Col>
            </Row>
          </form>
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
    </div>
  );
}

export default DocsAdd;

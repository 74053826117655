import {
  faCamera,
  faChevronLeft,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Button,
  Card,
  Col,
  Row,
  InputGroup,
  Form,
  ButtonGroup,
  Collapse,
  Alert,
  Modal,
} from "react-bootstrap";
import Helpers from "../../libs/Helpers";
import { faEdit, faSave } from "@fortawesome/free-regular-svg-icons";
import { Commet } from "react-loading-indicators";

function UsersView(props) {
  const [roles, setRoles] = React.useState([]);
  const [licencias, setLicencias] = React.useState([]);
  const [sucursales, setSucursales] = React.useState([]);

  const [nombres, setNombres] = React.useState();
  const [apellidos, setApellidos] = React.useState();
  const [correo, setCorreo] = React.useState();
  const [telefono, setTelefono] = React.useState();
  const [licencia, setLicencia] = React.useState();
  const [roller, setRoller] = React.useState();
  const [rut, setRut] = React.useState();
  const [estado, setEstado] = React.useState();
  const [foto, setFoto] = React.useState();
  const [congregation, setCongregation] = React.useState();

  const [alertOpen, setAlertOpen] = React.useState(false);
  const [textAlert, setTextAlert] = React.useState();
  const [variantAlert, setVariantAlert] = React.useState("warning");

  const fileRef = React.useRef();

  const [ronly, setRonly] = React.useState(true);
  const token = localStorage.getItem("session");
  const [loading, setLoading] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    Helpers.postAuth("/admins/one", "POST", token, {
      id: props.Usuario.selectId,
    }).then((data) => {
      setNombres(data.names);
      setApellidos(data.lastnames);
      setCorreo(data.email);
      setTelefono(data.phonenumber);
      setLicencia(data.licencia);
      setRoller(data.roll);
      setRut(data.rut);
      setEstado(data.status);
      setFoto(data.imagen64);
      setCongregation(data.congregation);
      setLoading(false);
    });

    Helpers.postAuth("/roles/all", "POST", token, {}).then((data) => {
      setRoles(data);
    });

    Helpers.postAuth("/licencias/all", "POST", token, {}).then((data) => {
      setLicencias(data);
    });
    Helpers.postAuth("/branches/all", "POST", token, {}).then((data) => {
      setSucursales(data);
    });
  }, [token, props]);

  const showAlert = (texto) => {
    setTextAlert(texto);
    setAlertOpen(true);
    setTimeout(() => {
      setAlertOpen(false);
    }, 3000);
  };

  const updateAdmin = () => {
    Helpers.postAuth("/admins/update", "POST", token, {
      id: props.Usuario.selectId,
      names: nombres,
      lastnames: apellidos,
      email: correo,
      phonenumber: telefono,
      licencia,
      roll: roller,
      rut,
      status: estado,
      imagen64: foto,
      congregation,
    }).then((data, err) => {
      if (err) throw err;
      if (data.modifiedCount === 1) {
        setVariantAlert("success");
        showAlert("Cambios Guardados con Exito");
        setTimeout(() => {
          props.Vista.setUserView(0);
        }, 3000);
      } else {
        setVariantAlert("info");
        showAlert("Sin Cambios");
      }
    });
  };

  const removeUser = () => {
    Helpers.postAuth("/admins/del", "POST", token, {
      id: props.Usuario.selectId,
    }).then((data, err) => {
      console.log(data);
      if (err) {
        setShowModal(false);
        setVariantAlert("danger");
        showAlert("Error Eliminado Usuario");
      } else {
        setShowModal(false);
        setVariantAlert("success");
        showAlert("Usuario Eliminado con Exito");
        setTimeout(() => {
          props.Vista.setUserView(0);
        }, 3000);
      }
    });
  };

  return (
    <div>
      {loading && (
        <div
          style={{
            zIndex: 1000,
            width: "100vw",
            height: "100vh",
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "rgba(255,255,255,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <Commet color="#32cd32" size="medium" text="" textColor="" />
          </div>
        </div>
      )}
      <Collapse
        in={alertOpen}
        dimension={"height"}
        timeout={300}
        className="fixed-top p-3 m-2 rounded"
      >
        <Alert
          variant={variantAlert}
          className="fw-bold text-center"
          style={{
            display: alertOpen ? "block" : "none",
          }}
          id="example-collapse-text"
        >
          {textAlert}
        </Alert>
      </Collapse>

      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        animation={true}
      >
        <Modal.Header className="border-0 bg-danger text-light">
          <Modal.Title>Eliminar Usuario</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>¿Esta Seguro que Desea Eliminar este Usuario?</p>
        </Modal.Body>

        <Modal.Footer className="border-0">
          <Button
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              setShowModal(false);
            }}
          >
            Cerrar
          </Button>
          <Button
            variant="danger"
            onClick={(e) => {
              e.preventDefault();
              removeUser();
            }}
          >
            Si, Eliminar
          </Button>
        </Modal.Footer>
      </Modal>

      <Card className="shadow-sm border-0 bg-transparent">
        <Card.Header className="border-0">
          <Button
            onClick={(e) => {
              e.preventDefault();
              props.Vista.setUserView(0);
            }}
            variant="transparent"
            className="fw-bold"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="fa-fw me-2" />
            Información del Usuario
          </Button>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col sm={2} md={3} style={{ display: 'flex', alignItems: 'start', justifyContent: 'center' }}>
              <div
                style={{
                  width: 150,
                  height: 200,
                  display: 'flex',
                  boxShadow: '0px 0px 10px #AABBCC',
                  borderRadius: 10,
                  backgroundImage: `url('${foto}')`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover'
                }}
              />
              <input
                type="file"
                ref={fileRef}
                className="invisible"
                accept="image/png, image/jpeg"
                value=""
                onChange={(e) => {
                  e.preventDefault();
                  const reader = new FileReader();
                  let baseString;
                  reader.onloadend = function () {
                    baseString = reader.result;
                    setFoto(baseString);
                  };
                  reader.readAsDataURL(e.target.files[0]);
                }}
              />
              {ronly ? (
                ""
              ) : (
                <Button
                  className="btn btn-sm mt-2 shadow-sm"
                  variant="dark"
                  onClick={() => {
                    fileRef.current.click();
                  }}
                >
                  <FontAwesomeIcon icon={faCamera} className="fa-fw me-2" />{" "}
                  Cargar Foto
                </Button>
              )}
            </Col>
            <Col sm={10} md={9}>
              <Card className="shadow-sm border-0 bg-transparent">
                <Card.Body>
                  <Row>
                    <Col sm={6}>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1" className="colLeft">
                          Nombres
                        </InputGroup.Text>
                        <Form.Control
                          value={nombres}
                          onChange={(e) => {
                            e.preventDefault();
                            setNombres(e.target.value);
                          }}
                          readOnly={ronly}
                        />
                      </InputGroup>
                    </Col>
                    <Col sm={6}>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1" className="colLeft">
                          Apellidos
                        </InputGroup.Text>
                        <Form.Control
                          value={apellidos}
                          onChange={(e) => {
                            e.preventDefault();
                            setApellidos(e.target.value);
                          }}
                          readOnly={ronly}
                        />
                      </InputGroup>
                    </Col>
                    <Col sm={6}>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1" className="colLeft">
                          Correo
                        </InputGroup.Text>
                        <Form.Control
                          value={correo}
                          onChange={(e) => {
                            e.preventDefault();
                            setCorreo(e.target.value);
                          }}
                          readOnly={ronly}
                        />
                      </InputGroup>
                    </Col>
                    <Col sm={6}>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1" className="colLeft">
                          Teléfono
                        </InputGroup.Text>
                        <Form.Control
                          value={telefono}
                          onChange={(e) => {
                            e.preventDefault();
                            setTelefono(e.target.value);
                          }}
                          readOnly={ronly}
                        />
                      </InputGroup>
                    </Col>
                    <Col sm={6}>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1" className="colLeft">
                          Licencia
                        </InputGroup.Text>
                        <Form.Select
                          value={licencia}
                          onChange={(e) => {
                            e.preventDefault();
                            setLicencia(e.target.value);
                          }}
                          disabled={ronly}
                        >
                          {licencias.map((datos, i) => (
                            <option
                              key={i}
                              value={datos.grant}
                              {...(datos.licencia === licencia
                                ? "selected"
                                : "")}
                            >
                              {datos.licencia}
                            </option>
                          ))}
                        </Form.Select>
                      </InputGroup>
                    </Col>
                    <Col sm={6}>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1" className="colLeft">
                          Roll
                        </InputGroup.Text>
                        <Form.Select
                          value={roller}
                          onChange={(e) => {
                            e.preventDefault();
                            setRoller(e.target.value);
                          }}
                          disabled={ronly}
                        >
                          {roles.map((datos, i) => (
                            <option
                              key={i}
                              value={datos.grant}
                              {...(datos.grant === roller ? "selected" : "")}
                            >
                              {datos.roll}
                            </option>
                          ))}
                        </Form.Select>
                      </InputGroup>
                    </Col>
                    <Col sm={6}>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1" className="colLeft">
                          RUT
                        </InputGroup.Text>
                        <Form.Control
                          type="text"
                          value={rut}
                          onChange={(e) => {
                            e.preventDefault();
                            setRut(e.target.value);
                          }}
                          readOnly={ronly}
                        />
                      </InputGroup>
                    </Col>
                    <Col sm={6}>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1" className="colLeft">
                          Estado
                        </InputGroup.Text>
                        <Form.Select
                          value={estado}
                          onChange={(e) => {
                            e.preventDefault();
                            setEstado(e.target.value);
                          }}
                          disabled={ronly}
                        >
                          <option value="0">Inactivo</option>
                          <option value="1">Activo</option>
                        </Form.Select>
                      </InputGroup>
                    </Col>
                    <Col sm={12}>
                      <Form.Select
                        value={congregation}
                        onChange={(e) => setCongregation(e.target.value)}
                        disabled={ronly}
                      >
                        <option value="">Seleccione...</option>
                        {sucursales.map((suc, id) => (
                          <option key={id} value={suc.nombre_sucursal}>
                            {suc.nombre_sucursal}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className="border-0">
                  <ButtonGroup className="w-100">
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        if (ronly === true) {
                          setRonly(false);
                        } else {
                          setRonly(true);
                          updateAdmin();
                        }
                      }}
                      variant={ronly ? "primary" : "success"}
                    >
                      {ronly ? (
                        <>
                          <FontAwesomeIcon
                            icon={faEdit}
                            className="fa-fw me-2"
                          />
                          Editar
                        </>
                      ) : (
                        <>
                          <FontAwesomeIcon
                            icon={faSave}
                            className="fa-fw me-2"
                          />
                          Guardar
                        </>
                      )}
                    </Button>
                    <Button
                      variant="danger"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowModal(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} className="fa-fw me-3" />
                      Eliminar
                    </Button>
                  </ButtonGroup>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="border-0"></Card.Footer>
      </Card>
    </div>
  );
}

export default UsersView;

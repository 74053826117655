import React from 'react'
import { Commet } from "react-loading-indicators";

function Loader() {
  return (
    <div
          style={{
            zIndex: 1000,
            width: "100vw",
            height: "100vh",
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "rgba(255,255,255,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <Commet color="#32cd32" size="medium" text="" textColor="" />
          </div>
        </div>
  )
}

export default Loader
import React from "react";
import MainLayout from "../../layouts/MainLayout";

function Eclesiastics() {
  return (
    <div>
      <MainLayout>
        Reportes Eclesíasticos
      </MainLayout>
    </div>
  );
}

export default Eclesiastics;

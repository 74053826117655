import { faChevronLeft, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Button,
  Card,
  Col,
  Row,
  InputGroup,
  Form,
  ButtonGroup,
  Collapse,
  Alert,
  Modal,
} from "react-bootstrap";
import Helpers from "../../libs/Helpers";
import { faEdit, faSave } from "@fortawesome/free-regular-svg-icons";
import { Commet } from "react-loading-indicators";

function FamilyView(props) {
  //const [sucursal, setSucursal] = React.useState();
  const [namefamily, setNameFamily] = React.useState();
  const [integrantes, setIntegrantes] = React.useState([]);

  const [alertOpen, setAlertOpen] = React.useState(false);
  const [textAlert, setTextAlert] = React.useState();
  const [variantAlert, setVariantAlert] = React.useState("warning");

  const [ronly, setRonly] = React.useState(true);
  const token = localStorage.getItem("session");
  const [loading, setLoading] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    Helpers.postAuth("/family/one", "POST", token, {
      id: props.Family.selectId,
    }).then((data) => {
      setNameFamily(data.name_family);
      Helpers.postAuth("/members/byfamily", "POST", token, {
        group_family: data.name_family,
      }).then((data) => {
        setIntegrantes(data);
      });
      setLoading(false);
    });
  }, [token, props]);

  const showAlert = (texto) => {
    setTextAlert(texto);
    setAlertOpen(true);
    setTimeout(() => {
      setAlertOpen(false);
    }, 3000);
  };

  const updateFamily = () => {
    Helpers.postAuth("/family/update", "POST", token, {
      id: props.Family.selectId,
      name_family: namefamily,
    }).then((data, err) => {
      if (err) {
        setShowModal(false);
        setVariantAlert("danger");
        showAlert("Error Actualizando Familia");
      } else {
        setShowModal(false);
        setVariantAlert("success");
        showAlert("Familia Actualizada con Exito");
        setTimeout(() => {
          props.Vista.setFamilyViewScreen(0);
        }, 3000);
      }
    });
  };

  const removeFamily = () => {
    Helpers.postAuth("/members/delbyfamily", "POST", token, {
      group_family: namefamily,
    }).then(() => {
      Helpers.postAuth("/family/del", "POST", token, {
        id: props.Family.selectId,
      }).then((data, err) => {
        if (err) {
          setShowModal(false);
          setVariantAlert("danger");
          showAlert("Error Eliminando Familia");
        } else {
          setShowModal(false);
          setVariantAlert("success");
          showAlert("Familia Eliminada con Exito");
          setTimeout(() => {
            props.Vista.setFamilyViewScreen(0);
          }, 3000);
        }
      });
    });
  };

  return (
    <div>
      {loading && (
        <div
          style={{
            zIndex: 1000,
            width: "100vw",
            height: "100vh",
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "rgba(255,255,255,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <Commet color="#32cd32" size="medium" text="" textColor="" />
          </div>
        </div>
      )}
      <Collapse
        in={alertOpen}
        dimension={"height"}
        timeout={300}
        className="fixed-top p-3 m-2 rounded"
      >
        <Alert
          variant={variantAlert}
          className="fw-bold text-center"
          style={{
            display: alertOpen ? "block" : "none",
          }}
          id="example-collapse-text"
        >
          {textAlert}
        </Alert>
      </Collapse>

      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        animation={true}
      >
        <Modal.Header className="border-0 bg-danger text-light">
          <Modal.Title>Eliminar Familia</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            ¿Esta Seguro que Desea Eliminar esta Familia y Todos sus
            Integrantes?
          </p>
        </Modal.Body>

        <Modal.Footer className="border-0">
          <Button
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              setShowModal(false);
            }}
          >
            Cerrar
          </Button>
          <Button
            variant="danger"
            onClick={(e) => {
              e.preventDefault();
              removeFamily();
            }}
          >
            Si, Eliminar
          </Button>
        </Modal.Footer>
      </Modal>

      <Card className="shadow-sm border-0 bg-transparent">
        <Card.Header className="border-0">
          <Button
            onClick={(e) => {
              e.preventDefault();
              props.Vista.setFamilyViewScreen(0);
            }}
            variant="transparent"
            className="fw-bold"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="fa-fw me-2" />
            Información de la Familia
          </Button>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <Card className="shadow-sm border-0 bg-transparent">
                <Card.Body>
                  <Row>
                    <Col sm={12}>
                      <InputGroup className="mb-3">
                        <InputGroup.Text
                          id="basic-addon1"
                          className="colLeft fw-bold"
                          style={{ width: 150 }}
                        >
                          Familia
                        </InputGroup.Text>
                        <Form.Control
                          value={namefamily}
                          onChange={(e) => {
                            e.preventDefault();
                            setNameFamily(e.target.value);
                          }}
                          readOnly={ronly}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <Card className="shadow-sm border-0 bg-transparent">
                        <Card.Header className="fw-bold border-0">
                          Integrantes
                        </Card.Header>
                        <Card.Body>
                          <Row>
                            <Col sm={4} className="bg-dark text-light p-2">
                              Nombres
                            </Col>
                            <Col sm={4} className="bg-dark text-light p-2">
                              Apellidos
                            </Col>
                            <Col sm={4} className="bg-dark text-light p-2">
                              Posición
                            </Col>
                            {integrantes.map((data, index) => (
                              <>
                                <Col
                                  key={index}
                                  sm={4}
                                  className="bg-light text-dark p-1"
                                >
                                  {data.names}
                                </Col>
                                <Col sm={4} className="bg-light text-dark p-1">
                                  {data.lastnames}
                                </Col>
                                <Col sm={4} className="bg-light text-dark p-1">
                                  {data.placefamily}
                                </Col>
                              </>
                            ))}
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className="border-0">
                  <ButtonGroup className="w-100">
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        if (ronly === true) {
                          setRonly(false);
                        } else {
                          setRonly(true);
                          updateFamily();
                        }
                      }}
                      variant={ronly ? "primary" : "success"}
                    >
                      {ronly ? (
                        <>
                          <FontAwesomeIcon
                            icon={faEdit}
                            className="fa-fw me-2"
                          />
                          Editar
                        </>
                      ) : (
                        <>
                          <FontAwesomeIcon
                            icon={faSave}
                            className="fa-fw me-2"
                          />
                          Guardar
                        </>
                      )}
                    </Button>
                    <Button
                      variant="danger"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowModal(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} className="fa-fw me-3" />
                      Eliminar
                    </Button>
                  </ButtonGroup>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}

// eslint-disable-next-line
export default FamilyView;

/*
<Col sm={12}>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1" className="colLeft fw-bold" style={{ width: 150 }}>
                          Congregación
                        </InputGroup.Text>
                        <Form.Control
                          value={sucursal}
                          onChange={(e) => {
                            e.preventDefault();
                            setSucursal(e.target.value);
                          }}
                          readOnly={ronly}
                        />
                      </InputGroup>
                    </Col>
*/

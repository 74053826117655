import React from "react";
import MainLayout from "../../layouts/MainLayout";

function Friends() {
  return (
    <div>
      <MainLayout>
        Amigos / Visitas
      </MainLayout>
    </div>
  );
}

export default Friends;

import React from "react";
import MainLayout from "../../layouts/MainLayout";
import OficialsList from "./OficialsList";
import OficialsAdd from "./OficialsAdd";


function Oficials() {
  const [OficialView, setOficialView] = React.useState(0)
  const [selectId, setSelectId] = React.useState(null);


  return (
    <div>
      <MainLayout>
        
      {
        OficialView === 0 ? <OficialsList Vista={{ OficialView, setOficialView }} Oficial={{ selectId, setSelectId }} /> : 
        OficialView === 1 ? <OficialsAdd Vista={{ OficialView, setOficialView }} /> : ''
        }
        
      </MainLayout>
    </div>
  );
}

export default Oficials;

import React, { useRef, useState, useEffect } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  InputGroup,
  Form,
  Collapse,
  Alert,
  Image,
  ButtonGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import Helpers from "../../libs/Helpers";
import Dictaphone from "../../componentes/Dictaphone";
import Firma from "../../componentes/Firma";

function MembersAdd(props) {
  const token = localStorage.getItem("session");

  const [group_family, setGroupFamily] = useState();
  const [names, setNames] = useState();
  const [lastnames, setLastnames] = useState();
  const [typedoc, setTypedoc] = useState();
  const [numdoc, setNumdoc] = useState();
  const [nationality, setNationality] = useState();
  const [placeofbirth, setPlaceofbirth] = useState();
  const [dateofbirth, setDateofbirth] = useState();
  const [gender, setGender] = useState();
  const [address, setAddress] = useState();
  const [phonenumber, setPhonenumber] = useState();
  const [civilstatus, setCivilstatus] = useState();
  const [placefamily, setPlacefamily] = useState();
  const [scholarship, setScholarship] = useState();
  const [congregation, setCongregation] = useState();
  const [occupation, setOccupation] = useState();
  const [datewaterbaptism, setDatewaterbaptism] = useState();
  const [citybaptism, setCitybaptism] = useState();
  const [shepherdbaptism, setPShepherdbaptism] = useState();
  const [holyspirit, setHolyspirit] = useState();
  const [positionsheld, setPositionsheld] = useState();
  const [howmeetlord, setHowmeetlord] = useState();
  const [addnote, setAddnote] = useState();
  const [photo, setPhoto] = useState();
  const [email, setEmail] = useState();

  const fileRef = useRef();

  const [ronly, setRonly] = React.useState(false);
  const focusedInputRef = useRef(null);
  const [indice, setIndice] = useState();

  const [alertOpen, setAlertOpen] = useState(false);
  const [textAlert, setTextAlert] = useState();
  const [variantAlert, setVariantAlert] = useState("warning");

  const [grupo_familiar, setGrupoFamiliar] = useState([]);
  const [sucursales, setSucursales] = useState([]);
  const [firma, setFirma] = useState();

  useEffect(() => {
    console.log(firma);
    Helpers.postAuth("/family/all", "POST", token, {}).then((data) => {
      setGrupoFamiliar(data);
    });
    Helpers.postAuth("/branches/all", "POST", token, {}).then((data1) => {
      setSucursales(data1);
    });
  }, [token, firma]);

  const showAlert = (texto) => {
    setTextAlert(texto);
    setAlertOpen(true);
    setTimeout(() => {
      setAlertOpen(false);
    }, 3000);
  };

  const validateInput = (msg) => {
    setVariantAlert("warning");
    showAlert(msg);
  };

  const handleFocus = (event) => {
    setIndice(event.target.tabIndex);
    setRonly(true);
    focusedInputRef.current = event.target;
  };

  const handleBlur = () => {};

  const Dictado = () => {
    switch (indice) {
      case 1:
        return <Dictaphone foco={focusedInputRef} onChange={setNames} />;
      case 2:
        return <Dictaphone foco={focusedInputRef} onChange={setLastnames} />;
      case 3:
        return <Dictaphone foco={focusedInputRef} onChange={setNumdoc} />;
      case 5:
        return <Dictaphone foco={focusedInputRef} onChange={setPlaceofbirth} />;
      case 6:
        return <Dictaphone foco={focusedInputRef} onChange={setAddress} />;
      case 7:
        return <Dictaphone foco={focusedInputRef} onChange={setPhonenumber} />;
      case 8:
        return <Dictaphone foco={focusedInputRef} onChange={setCitybaptism} />;
      case 9:
        return (
          <Dictaphone foco={focusedInputRef} onChange={setPShepherdbaptism} />
        );
      case 10:
        return (
          <Dictaphone foco={focusedInputRef} onChange={setPositionsheld} />
        );
      case 11:
        return <Dictaphone foco={focusedInputRef} onChange={setHowmeetlord} />;
      case 12:
        return <Dictaphone foco={focusedInputRef} onChange={setAddnote} />;
      default:
        return null;
    }
  };

  const addMember = () => {
    !names
      ? validateInput("Debes Ingresar los Nombres")
      : !lastnames
      ? validateInput("Debes Ingresar los Apellidos")
      : !typedoc
      ? validateInput("Debes Seleccionar el Tipo de Documento")
      : !numdoc
      ? validateInput("Debes Ingresar el Número del Documento")
      : !nationality
      ? validateInput("Debes Seleccionar el Pais de Origen")
      : !placeofbirth
      ? validateInput("Debes Ingresar el Lugar de Nacimiento")
      : !dateofbirth
      ? validateInput("Debes Seleccionar la Fecha de Nacimiento")
      : !gender
      ? validateInput("Debes Seleccionar el Género")
      : !address
      ? validateInput("Debes Incluir la Dirección de Residencia")
      : !phonenumber
      ? validateInput("Debes Incluir el Número de Te´lefono")
      : !civilstatus
      ? validateInput("Debes Seleccionar el Estado Civil")
      : !group_family
      ? validateInput("Debes Seleccionar un Grupo Familiar")
      : !placefamily
      ? validateInput("Debes Seleccionar el Lugar que Ocupa en la Familia")
      : !scholarship
      ? validateInput("Debes Seleccionar el Nivel de Estudios")
      : !congregation
      ? validateInput("Debes Seleccionar la Congregación a la que pertenece")
      : !occupation
      ? validateInput("Debes Seleccionar la Ocupación")
      : !photo
      ? validateInput("Debes Incluir el Registro Fotográfico")
      : !email
      ? validateInput("Debes Ingresar la Dirección de Correo")
      : Helpers.postAuth("/members/add", "POST", token, {
          group_family,
          names,
          lastnames,
          typedoc,
          numdoc,
          nationality,
          placeofbirth,
          dateofbirth,
          gender,
          address,
          phonenumber,
          civilstatus,
          placefamily,
          scholarship,
          congregation,
          occupation,
          datewaterbaptism,
          citybaptism,
          shepherdbaptism,
          holyspirit,
          positionsheld,
          howmeetlord,
          addnote,
          photo,
          email,
          firma,
        }).then((data, err) => {
          if (err) throw err;
          setVariantAlert("info");
          showAlert("Registro Creado con Exito");
          setTimeout(() => {
            props.Vista.setMemberView(0);
          }, 3000);
        });
  };

  //const handleValueChangeFirma = (key, value) => {
  //  setValuesFirma((prevValues) => ({ ...prevValues, [key]: value }));
  //};

  return (
    <div>
      <Collapse
        in={alertOpen}
        dimension={"height"}
        timeout={300}
        className="fixed-top p-3 m-2 rounded"
      >
        <Alert
          variant={variantAlert}
          className="fw-bold text-center"
          style={{
            display: alertOpen ? "block" : "none",
          }}
          id="example-collapse-text"
        >
          {textAlert}
        </Alert>
      </Collapse>
      <Card className="shadow-sm border-0 bg-transparent">
        <Card.Header className="border-0">
          <Button
            onClick={(e) => {
              e.preventDefault();
              props.Vista.setMemberView(0);
            }}
            variant="transparent"
            className="fw-bold"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="fa-fw me-2" />
            Agregar Nuevo Registro
          </Button>
        </Card.Header>
        <Card.Body>
          <form onSubmit={(e) => e.preventDefault()}>
            <Row>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Nombres
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    required
                    value={names}
                    onChange={(e) => {
                      setNames(e.target.value);
                    }}
                    tabIndex={1}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    className="bg-transparent"
                  />
                  {indice === 1 && ronly === true && (
                    <InputGroup.Text className="p-0 d-flex">
                      <Dictado />
                    </InputGroup.Text>
                  )}
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup className="bg-transparent">
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Apellidos
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    required
                    value={lastnames}
                    onChange={(e) => {
                      setLastnames(e.target.value);
                    }}
                    tabIndex={2}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    className="bg-transparent"
                  />
                  {indice === 2 && ronly === true && (
                    <InputGroup.Text className="p-0 d-flex">
                      <Dictado />
                    </InputGroup.Text>
                  )}
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Tipo Documento
                  </InputGroup.Text>
                  <Form.Select
                    required
                    value={typedoc}
                    onChange={(e) => {
                      setTypedoc(e.target.value);
                    }}
                    onFocus={handleFocus}
                    className="bg-transparent"
                  >
                    <option value="">Seleccione...</option>
                    <option value="pasaporte">Pasaporte</option>
                    <option value="rut">RUT</option>
                  </Form.Select>
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Documento
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    required
                    value={numdoc}
                    onChange={(e) => {
                      setNumdoc(e.target.value);
                    }}
                    tabIndex={3}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    className="bg-transparent"
                  />
                  {indice === 3 && ronly === true && (
                    <InputGroup.Text className="p-0 d-flex">
                      <Dictado />
                    </InputGroup.Text>
                  )}
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Nacionalidad
                  </InputGroup.Text>
                  <Form.Select
                    required
                    value={nationality}
                    onChange={(e) => {
                      setNationality(e.target.value);
                    }}
                    onFocus={handleFocus}
                    className="bg-transparent"
                  >
                    <option value="">Seleccione...</option>
                    <option value="Argentina">Argentina</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Brasil">Brasil</option>
                    <option value="Chile">Chile</option>
                    <option value="Colombia">Colombia</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Guatemala">Guatemala</option>
                  </Form.Select>
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Ciudad Nacimiento
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    required
                    value={placeofbirth}
                    onChange={(e) => {
                      setPlaceofbirth(e.target.value);
                    }}
                    tabIndex={5}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                  {indice === 5 && ronly === true && (
                    <InputGroup.Text className="p-0 d-flex">
                      <Dictado />
                    </InputGroup.Text>
                  )}
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Fecha Nacimiento
                  </InputGroup.Text>
                  <Form.Control
                    type="date"
                    required
                    value={dateofbirth}
                    onChange={(e) => {
                      setDateofbirth(e.target.value);
                    }}
                    onFocus={handleFocus}
                    className="bg-transparent"
                  />
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Género
                  </InputGroup.Text>
                  <Form.Select
                    required
                    value={gender}
                    onChange={(e) => {
                      setGender(e.target.value);
                    }}
                    onFocus={handleFocus}
                    className="bg-transparent"
                  >
                    <option value="">Seleccione...</option>
                    <option value="Femenino">Femenino</option>
                    <option value="Masculino">Masculino</option>
                  </Form.Select>
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Dirección
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    required
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                    tabIndex={6}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                  {indice === 6 && ronly === true && (
                    <InputGroup.Text className="p-0 d-flex">
                      <Dictado />
                    </InputGroup.Text>
                  )}
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Teléfono
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    required
                    value={phonenumber}
                    onChange={(e) => {
                      setPhonenumber(e.target.value);
                    }}
                    tabIndex={7}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                  {indice === 7 && ronly === true && (
                    <InputGroup.Text className="p-0 d-flex">
                      <Dictado />
                    </InputGroup.Text>
                  )}
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Correo
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    required
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    onFocus={handleFocus}
                    className="bg-transparent"
                  />
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Estado Civil
                  </InputGroup.Text>
                  <Form.Select
                    required
                    value={civilstatus}
                    onChange={(e) => {
                      setCivilstatus(e.target.value);
                    }}
                    onFocus={handleFocus}
                    className="bg-transparent"
                  >
                    <option value="">Seleccione...</option>
                    <option value="Soltero(a)">Soltero(a)</option>
                    <option value="Casado(a)">Casado(a)</option>
                    <option value="Viudo(a)">Viudo(a)</option>
                    <option value="Divorciado(a)">Divorciado(a)</option>
                  </Form.Select>
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Grupo Familiar
                  </InputGroup.Text>
                  <Form.Select
                    required
                    value={group_family}
                    onChange={(e) => {
                      setGroupFamily(e.target.value);
                    }}
                    onFocus={handleFocus}
                    className="bg-transparent"
                  >
                    <option value="">Seleccione...</option>
                    {grupo_familiar.map((data, id) => (
                      <option key={id} value={data.name_family}>
                        {data.name_family}
                      </option>
                    ))}
                    <option value="without">Sin Familia</option>
                  </Form.Select>
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Lugar en la Familia
                  </InputGroup.Text>
                  <Form.Select
                    required
                    value={placefamily}
                    onChange={(e) => {
                      setPlacefamily(e.target.value);
                    }}
                    onFocus={handleFocus}
                    className="bg-transparent"
                  >
                    <option value="">Seleccione...</option>
                    <option value="Padre">Padre</option>
                    <option value="Madre">Madre</option>
                    <option value="Hijo(a)">Hijo(a)</option>
                    <option value="Sobrino(a)">Sobrino(a)</option>
                    <option value="Primo(a)">Primo(a)</option>
                    <option value="Hermano(a)">Hermano(a)</option>
                    <option value="Abuelo(a)">Abuelo(a)</option>
                    <option value="Otro">Otro</option>
                  </Form.Select>
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Nivel Educación
                  </InputGroup.Text>
                  <Form.Select
                    required
                    value={scholarship}
                    onChange={(e) => {
                      setScholarship(e.target.value);
                    }}
                    onFocus={handleFocus}
                    className="bg-transparent"
                  >
                    <option value="">Seleccione...</option>
                    <option value="Pre-Escolar">Pre-Escolar</option>
                    <option value="Enseñanza Básica">Enseñanza Básica</option>
                    <option value="Enseñanza Media">Enseñanza Media</option>
                    <option value="Educación Superior">
                      Educación Superior
                    </option>
                    <option value="Post-Titulos">Post-Titulos</option>
                  </Form.Select>
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Ocupación
                  </InputGroup.Text>
                  <Form.Select
                    required
                    value={occupation}
                    onChange={(e) => {
                      setOccupation(e.target.value);
                    }}
                    onFocus={handleFocus}
                    className="bg-transparent"
                  >
                    <option value="">Seleccione...</option>
                    <option value="Empleado">Empleado</option>
                    <option value="Independiente">Independiente</option>
                    <option value="Hogar">Hogar</option>
                  </Form.Select>
                </InputGroup>
              </Col>
              <Col sm={12}>
                <hr
                  style={{
                    border: "4px solid #666",
                  }}
                />
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Fecha Bautismo
                  </InputGroup.Text>
                  <Form.Control
                    type="date"
                    required
                    value={datewaterbaptism}
                    onChange={(e) => {
                      setDatewaterbaptism(e.target.value);
                    }}
                    onFocus={handleFocus}
                    className="bg-transparent"
                  />
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Lugar Bautismo
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    required
                    value={citybaptism}
                    onChange={(e) => {
                      setCitybaptism(e.target.value);
                    }}
                    tabIndex={8}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                  {indice === 8 && ronly === true && (
                    <InputGroup.Text className="p-0 d-flex">
                      <Dictado />
                    </InputGroup.Text>
                  )}
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Pastor Bautismo
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    required
                    value={shepherdbaptism}
                    onChange={(e) => {
                      setPShepherdbaptism(e.target.value);
                    }}
                    tabIndex={9}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                  {indice === 9 && ronly === true && (
                    <InputGroup.Text className="p-0 d-flex">
                      <Dictado />
                    </InputGroup.Text>
                  )}
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Espíritu Santo
                  </InputGroup.Text>
                  <Form.Control
                    type="date"
                    required
                    value={holyspirit}
                    onChange={(e) => {
                      setHolyspirit(e.target.value);
                    }}
                    onFocus={handleFocus}
                    className="bg-transparent"
                  />
                </InputGroup>
              </Col>
              <Col sm={12} md={12} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Congregación
                  </InputGroup.Text>
                  <Form.Select
                    required
                    value={congregation}
                    onChange={(e) => {
                      setCongregation(e.target.value);
                    }}
                    onFocus={handleFocus}
                    className="bg-transparent"
                  >
                    <option value="">Seleccione...</option>
                    {sucursales.map((data, id) => (
                      <option key={id} value={data.nombre_sucursal}>
                        {data.nombre_sucursal}
                      </option>
                    ))}
                  </Form.Select>
                </InputGroup>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <Card className="bg-transparent border-0 shadow-sm">
                  <Card.Header className="border-0 fw-bold">
                    <Row>
                      <Col sm={10}>Cargos Desempeñados</Col>
                      {indice === 10 && ronly === true && (
                        <Col sm={2}>
                          <Dictado />
                        </Col>
                      )}
                    </Row>
                  </Card.Header>
                  <Card.Body className="p-1">
                    <Form.Control
                      as="textarea"
                      rows={4}
                      value={positionsheld}
                      onChange={(e) => setPositionsheld(e.target.value)}
                      tabIndex={10}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    ></Form.Control>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={12} md={6} className="mt-2">
                <Card className="bg-transparent border-0 shadow-sm">
                  <Card.Header className="border-0 fw-bold">
                    <Row>
                      <Col sm={10}>Como Conoció al Señor</Col>
                      {indice === 11 && ronly === true && (
                        <Col sm={2}>
                          <Dictado />
                        </Col>
                      )}
                    </Row>
                  </Card.Header>
                  <Card.Body className="p-1">
                    <Form.Control
                      as="textarea"
                      rows={4}
                      value={howmeetlord}
                      onChange={(e) => setHowmeetlord(e.target.value)}
                      tabIndex={11}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    ></Form.Control>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={12} md={12} className="mt-2">
                <Card className="bg-transparent border-0 shadow-sm">
                  <Card.Header className="border-0 fw-bold">
                    <Row>
                      <Col sm={10}>Notas Eventuales</Col>
                      {indice === 12 && ronly === true && (
                        <Col sm={2}>
                          <Dictado />
                        </Col>
                      )}
                    </Row>
                  </Card.Header>
                  <Card.Body className="p-1">
                    <Form.Control
                      as="textarea"
                      rows={4}
                      value={addnote}
                      onChange={(e) => setAddnote(e.target.value)}
                      tabIndex={12}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    ></Form.Control>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={6} className="mt-2">
                <Card className="bg-transparent border-0 shadow-sm">
                  <Card.Header className="border-0 fw-bold">
                    Registro Fotográfico
                  </Card.Header>
                  <Card.Body
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 183
                    }}
                  >
                    {photo && (
                      <Image
                        className="imageAdd"
                        height={160}
                        src={photo}
                        style={{
                          margin: 20,
                          width: 'auto'
                        }}
                      />
                    )}
                    <input
                      type="file"
                      ref={fileRef}
                      className="invisible"
                      accept="image/png, image/jpeg"
                      value=""
                      onChange={(e) => {
                        e.preventDefault();
                        const reader = new FileReader();
                        let baseString;
                        reader.onloadend = function () {
                          baseString = reader.result;
                          setPhoto(baseString);
                        };
                        reader.readAsDataURL(e.target.files[0]);
                      }}
                    />
                    <Button
                      className="btn btn-lg shadow"
                      variant="light"
                      onClick={() => {
                        fileRef.current.click();
                      }}
                    >
                      <FontAwesomeIcon icon={faCamera} className="fa-fw" beat />{" "}
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={6}>
              <Firma dataSign={{firma, setFirma}} disable={ronly} />
              </Col>
            </Row>

            <Row>
              <Col sm={12} className="mt-4">
                <ButtonGroup className="w-100">
                  <Button
                    className="w-100"
                    onClick={(e) => {
                      e.preventDefault();
                      addMember();
                    }}
                  >
                    <FontAwesomeIcon icon={faSave} className="fa-fw me-2" />{" "}
                    Guardar Resgistro
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </form>

          {ronly && (
            <div
              style={{
                position: "fixed",
                top: 20,
                right: 20,
                zIndex: 1000000,
              }}
            ></div>
          )}
        </Card.Body>
        <Card.Footer className="border-0"></Card.Footer>
      </Card>
    </div>
  );
}

export default MembersAdd;

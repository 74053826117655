import React from "react";
import MainLayout from "../../layouts/MainLayout";

function Actives() {
  return (
    <div>
      <MainLayout>
        Inventario de Activos
      </MainLayout>
    </div>
  );
}

export default Actives;

import {
  faChevronLeft,
  faPhoneFlip,
  faRobot,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Button,
  Card,
  Col,
  Row,
  InputGroup,
  Form,
  ButtonGroup,
  Collapse,
  Alert,
  Modal,
} from "react-bootstrap";
import Helpers from "../../libs/Helpers";
import {
  faEdit,
  faPaperPlane,
  faSave,
} from "@fortawesome/free-regular-svg-icons";
import { Commet } from "react-loading-indicators";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import Mapa from "../../libs/Mapa";

function BranchesView(props) {
  const [nombre_sucursal, setNombre_Sucursal] = React.useState();
  const [pastor, setPastor] = React.useState();
  const [direccion, setDireccion] = React.useState();
  const [telefono, setTelefono] = React.useState();
  const [tipo_sucursal, setTipoSucursal] = React.useState();
  const [latitud, setLatitud] = React.useState();
  const [longitud, setLongitud] = React.useState();

  const [numberWath, setNumberWath] = React.useState();

  const [alertOpen, setAlertOpen] = React.useState(false);
  const [textAlert, setTextAlert] = React.useState();
  const [variantAlert, setVariantAlert] = React.useState("warning");

  const [ronly, setRonly] = React.useState(true);
  const token = localStorage.getItem("session");
  const [loading, setLoading] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    Helpers.postAuth("/branches/one", "POST", token, {
      id: props.Sucursal.selectId,
    }).then((data) => {
      setNombre_Sucursal(data.nombre_sucursal);
      setPastor(data.pastor);
      setDireccion(data.direccion);
      setTelefono(data.telefono);
      setTipoSucursal(data.tipo_sucursal);
      setLatitud(data.latitud);
      setLongitud(data.longitud);
      setLoading(false);
    });
  }, [token, props]);

  const showAlert = (texto) => {
    setTextAlert(texto);
    setAlertOpen(true);
    setTimeout(() => {
      setAlertOpen(false);
    }, 3000);
  };

  const getBot = async () => {
    if (direccion) {
      const location = await Helpers.getLocation(direccion);
      setLatitud(location.lat);
      setLongitud(location.lng);
    } else {
      setVariantAlert("warning");
      showAlert("Debes Ingresar la Dirección");
    }
  };

  
  const updateAdmin = () => {
    Helpers.postAuth("/branches/update", "POST", token, {
      id: props.Sucursal.selectId,
      nombre_sucursal,
      pastor,
      direccion,
      telefono,
      tipo_sucursal,
      latitud,
      longitud,
    }).then((data, err) => {
      if (err) throw err;
      if (data.modifiedCount === 1) {
        setVariantAlert("success");
        showAlert("Cambios Guardados con Exito");
        setTimeout(() => {
          props.Vista.setBranchView(0);
        }, 3000);
      } else {
        setVariantAlert("info");
        showAlert("Sin Cambios");
      }
    });
  };

  const removeSucursal = () => {
    Helpers.postAuth("/branches/del", "POST", token, {
      id: props.Sucursal.selectId,
    }).then((data, err) => {
      if (err) {
        setShowModal(false);
        setVariantAlert("danger");
        showAlert("Error Eliminando Sucursal");
      } else {
        setShowModal(false);
        setVariantAlert("success");
        showAlert("Sucursal Eliminada con Exito");
        setTimeout(() => {
          props.Vista.setBranchView(0);
        }, 3000);
      }
    });
  };

  const sharedWhats = () => {
    if (!numberWath) {
      setVariantAlert("danger");
      showAlert("Ingresa el Número para Enviar por Whatsapp");
    } else {
      Helpers.sendAddress(
        `${nombre_sucursal}`,
        `\nPastor: ${pastor}\nDirección: ${direccion}\nTeléfono: ${telefono}\n\nUbicación en el Mapa:\nhttps://www.google.com/maps/?q=${latitud},${longitud}`,
        numberWath
      );
    }
  };

  return (
    <div>
      {loading && (
        <div
          style={{
            zIndex: 1000,
            width: "100vw",
            height: "100vh",
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "rgba(255,255,255,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <Commet color="#32cd32" size="medium" text="" textColor="" />
          </div>
        </div>
      )}
      <Collapse
        in={alertOpen}
        dimension={"height"}
        timeout={300}
        className="fixed-top p-3 m-2 rounded"
      >
        <Alert
          variant={variantAlert}
          className="fw-bold text-center"
          style={{
            display: alertOpen ? "block" : "none",
          }}
          id="example-collapse-text"
        >
          {textAlert}
        </Alert>
      </Collapse>

      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        animation={true}
      >
        <Modal.Header className="border-0 bg-danger text-light">
          <Modal.Title>Eliminar Sucursal</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>¿Esta Seguro que Desea Eliminar esta Sucursal?</p>
        </Modal.Body>

        <Modal.Footer className="border-0">
          <Button
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              setShowModal(false);
            }}
          >
            Cerrar
          </Button>
          <Button
            variant="danger"
            onClick={(e) => {
              e.preventDefault();
              removeSucursal();
            }}
          >
            Si, Eliminar
          </Button>
        </Modal.Footer>
      </Modal>

      <Card className="shadow-sm border-0 bg-transparent">
        <Card.Header className="border-0">
          <Button
            onClick={(e) => {
              e.preventDefault();
              props.Vista.setBranchView(0);
            }}
            variant="transparent"
            className="fw-bold"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="fa-fw me-2" />
            Información de la Sucursal
          </Button>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <Card className="shadow-sm border-0 bg-transparent">
                <Card.Body>
                  <Row>
                    <Col sm={6}>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1" className="colLeft">
                          Nombre
                        </InputGroup.Text>
                        <Form.Control
                          value={nombre_sucursal}
                          onChange={(e) => {
                            e.preventDefault();
                            setNombre_Sucursal(e.target.value);
                          }}
                          readOnly={ronly}
                        />
                      </InputGroup>
                    </Col>
                    <Col sm={6}>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1" className="colLeft">
                          Distrito
                        </InputGroup.Text>
                        <Form.Control
                          value={pastor}
                          onChange={(e) => {
                            e.preventDefault();
                            setPastor(e.target.value);
                          }}
                          readOnly={ronly}
                        />
                      </InputGroup>
                    </Col>
                    <Col sm={6}>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1" className="colLeft">
                          Dirección
                        </InputGroup.Text>
                        <Form.Control
                          value={direccion}
                          onChange={(e) => {
                            e.preventDefault();
                            setDireccion(e.target.value);
                          }}
                          readOnly={ronly}
                        />
                      </InputGroup>
                    </Col>
                    <Col sm={6}>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1" className="colLeft">
                          Teléfono
                        </InputGroup.Text>
                        <Form.Control
                          value={telefono}
                          onChange={(e) => {
                            e.preventDefault();
                            setTelefono(e.target.value);
                          }}
                          readOnly={ronly}
                        />
                      </InputGroup>
                    </Col>
                    <Col sm={6}>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1" className="colLeft">
                          Tipo
                        </InputGroup.Text>
                        <Form.Select
                          value={tipo_sucursal}
                          onChange={(e) => {
                            e.preventDefault();
                            setTipoSucursal(e.target.value);
                          }}
                          disabled={ronly}
                        >
                          <option value="local">Local</option>
                          <option value="nacional">Nacional</option>
                          <option value="misión">Misión</option>
                        </Form.Select>
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <Card className="mt-2 bg-transparent shadow-sm border-0">
                        <Card.Header
                          className="fw-bold border-0"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div className="fw-bold">Geo Referenciación</div>
                          <div>
                            <Button
                              className="btn btn-sm shadow-sm"
                              variant="dark"
                              onClick={async (e) => {
                                e.preventDefault();
                                getBot();
                              }}
                              disabled={ronly}
                            >
                              <FontAwesomeIcon
                                icon={faRobot}
                                className="fa-fw me-2"
                              />
                              GeoBot
                            </Button>
                          </div>
                        </Card.Header>
                        <Card.Body>
                          <Row>
                            <Col sm={12} md={6} className="mt-2">
                              <InputGroup>
                                <InputGroup.Text
                                  id="basic-addon1"
                                  className="colLeft"
                                >
                                  Latitud
                                </InputGroup.Text>
                                <Form.Control
                                  type="number"
                                  required
                                  value={latitud}
                                  onChange={(e) => {
                                    setLatitud(e.target.value);
                                  }}
                                  readOnly
                                />
                              </InputGroup>
                            </Col>
                            <Col sm={12} md={6} className="mt-2">
                              <InputGroup>
                                <InputGroup.Text
                                  id="basic-addon1"
                                  className="colLeft"
                                >
                                  Longitud
                                </InputGroup.Text>
                                <Form.Control
                                  type="number"
                                  required
                                  value={longitud}
                                  onChange={(e) => {
                                    setLongitud(e.target.value);
                                  }}
                                  readOnly
                                />
                              </InputGroup>
                            </Col>
                          </Row>
                        </Card.Body>
                        <Card.Footer className="p-0">
                          {!loading && 
                          <Mapa latitud={latitud} longitud={longitud} nombre_sucursal={nombre_sucursal} direccion={direccion} /> }
                        </Card.Footer>
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className="border-0">
                  <ButtonGroup className="w-100">
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        if (ronly === true) {
                          setRonly(false);
                        } else {
                          setRonly(true);
                          updateAdmin();
                        }
                      }}
                      variant={ronly ? "primary" : "success"}
                    >
                      {ronly ? (
                        <>
                          <FontAwesomeIcon
                            icon={faEdit}
                            className="fa-fw me-2"
                          />
                          Editar
                        </>
                      ) : (
                        <>
                          <FontAwesomeIcon
                            icon={faSave}
                            className="fa-fw me-2"
                          />
                          Guardar
                        </>
                      )}
                    </Button>
                    <Button
                      variant="danger"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowModal(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} className="fa-fw me-3" />
                      Eliminar
                    </Button>
                  </ButtonGroup>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="border-0">
          <Card className="shadow-sm bg-transparent border-0">
            <Card.Header className="fw-bold border-0">
              <FontAwesomeIcon icon={faWhatsapp} className="fa-fw me-2" />
              Compartir por Whatsapp
            </Card.Header>
            <Card.Body>
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faPhoneFlip} shake />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  value={numberWath}
                  onChange={(e) => setNumberWath(e.target.value)}
                />
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    sharedWhats();
                  }}
                >
                  <FontAwesomeIcon icon={faPaperPlane} className="fa-fw me-2" />
                  Enviar
                </Button>
              </InputGroup>
            </Card.Body>
          </Card>
        </Card.Footer>
      </Card>
    </div>
  );
}

// eslint-disable-next-line
export default BranchesView;

import React from "react";
import { PDFViewer } from "@react-pdf/renderer";
import PDFDownloader from "../../libs/PDFDownloader";
import { Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Commet } from "react-loading-indicators";

function MemberPDF(props) {
  const [loading, setLoading] = React.useState(false);

  const NewPDF = () => {
    return (
      <PDFDownloader
        id={props.Member.selectId}
        file={`IPUNSOFT_Membresia_${props.Member.selectId}`}
        estado={{ loading, setLoading }}
      />
    );
  };

  
  return (
    <>
      <Card className="shadow-sm border-0 bg-transparent h-100">
        <Card.Header
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            border: 0
          }}
        >
          <Button
            onClick={(e) => {
              e.preventDefault();
              props.Vista.setMemberView(0);
            }}
            variant="transparent"
            className="fw-bold"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="fa-fw me-2" />
            Visor de Membresía
          </Button>
        </Card.Header>
        <Card.Body className="p-1">
        {loading === false && (
        <div
          style={{
            zIndex: 1000,
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "rgba(255,255,255,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          <div>
            <Commet color="#32cd32" size="medium" text="" textColor="" />
            <br />
            Compilando Membresía
          </div>
        </div>
      )}
          <PDFViewer
            width={"100%"}
            height={"100%"}
            className="rounded"
            showToolbar={true}
          >
            <NewPDF />
          </PDFViewer>
        </Card.Body>
      </Card>
    </>
  );
}

export default MemberPDF;

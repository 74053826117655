import React from "react";
import MainLayout from "../../layouts/MainLayout";
import UsersList from "./UsersList";
import UsersView from "./UsersView";
import UsersAdd from "./UsersAdd";

function Users() {
  let [UserView, setUserView] = React.useState(0)
  const [selectId, setSelectId] = React.useState(null);

  return (
    <div>
      <MainLayout>
        {
        UserView === 0 ? <UsersList Vista={{ UserView, setUserView }} Usuario={{ selectId, setSelectId }} /> : UserView === 1 ? <UsersView Vista={{ UserView, setUserView }} Usuario={{ selectId, setSelectId }} /> : UserView === 2 ? <UsersAdd Vista={{ UserView, setUserView }} /> : ''
        }
        
      </MainLayout>
    </div>
  );
}

export default Users;

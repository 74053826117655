import {
  faBookBible,
  faCamera,
  faChevronLeft,
  faCodeBranch,
  faEarthAmericas,
  faGlobe,
  faGraduationCap,
  faHandsHolding,
  faMapLocation,
  faPeopleRoof,
  faPersonPraying,
  faPersonWalking,
  faPhone,
  faStreetView,
  faTrash,
  faUserDoctor,
  faVenusMars,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Row,
  InputGroup,
  Form,
  ButtonGroup,
  Collapse,
  Alert,
  Modal,
  Image,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import Helpers from "../../libs/Helpers";
import {
  faCalendar,
  faCalendarDays,
  faEdit,
  faEnvelope,
  faIdCard,
  faNoteSticky,
  faSave,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import { Commet } from "react-loading-indicators";
import Dictaphone from "../../componentes/Dictaphone";
import Firma from "../../componentes/Firma";

function MembersView(props) {
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [textAlert, setTextAlert] = React.useState();
  const [variantAlert, setVariantAlert] = React.useState("warning");

  const [ronly, setRonly] = React.useState(true);
  const token = localStorage.getItem("session");
  const [loading, setLoading] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);

  const [names, setNames] = React.useState();
  const [lastnames, setLastNames] = React.useState();
  const [typedoc, setTypeDoc] = React.useState();
  const [numdoc, setNumDoc] = React.useState();
  const [nationality, setNationality] = React.useState();
  const [placeofbirth, setPlaceOfBirth] = React.useState();
  const [dateofbirth, setDateOfBirth] = React.useState();
  const [gender, setGender] = React.useState();
  const [groupFamily, setGroupFamily] = React.useState();
  const [address, setAddress] = React.useState();
  const [phonenumber, setPhoneNumber] = React.useState();
  const [civilstatus, setCivilStatus] = React.useState();
  const [placefamily, setPlaceFamily] = React.useState();
  const [scholarship, setScholarShip] = React.useState();
  const [congregation, setCongregation] = React.useState();
  const [occupation, setOccupation] = React.useState();
  const [datewaterbaptism, setDateWaterBaptism] = React.useState();
  const [citybaptism, setCityBaptism] = React.useState();
  const [shepherdbaptism, setShepherdBaptism] = React.useState();
  const [holyspirit, setHolySpirit] = React.useState();
  const [positionsheld, setPositionSheld] = React.useState();
  const [howmeetlord, setHowMeetLord] = React.useState();
  const [addnote, setAddNote] = React.useState();
  const [photo, setPhoto] = React.useState();
  const [email, setEmail] = React.useState();
  const [firma, setFirma] = useState();
  const [familys, setFamilys] = React.useState([]);
  const fileRef = useRef();
  const focusedInputRef = useRef(null);
  const [indice, setIndice] = useState();

  const handleFocus = (event) => {
    setIndice(event.target.tabIndex);
    focusedInputRef.current = event.target;
  };

  React.useEffect(() => {
    try {
      Helpers.postAuth("/members/one", "POST", token, {
        id: props.Member.selectId,
      }).then((data) => {
        setNames(data.names);
        setLastNames(data.lastnames);
        setTypeDoc(data.typedoc.toUpperCase());
        setNumDoc(data.numdoc);
        setNationality(data.nationality);
        setPlaceOfBirth(data.placeofbirth);
        setDateOfBirth(data.dateofbirth);
        setGender(data.gender);
        setGroupFamily(data.group_family);
        setAddress(data.address);
        setPhoneNumber(data.phonenumber);
        setCivilStatus(data.civilstatus);
        setPlaceFamily(data.placefamily);
        setScholarShip(data.scholarship);
        setCongregation(data.congregation);
        setOccupation(data.occupation);
        setDateWaterBaptism(data.datewaterbaptism);
        setCityBaptism(data.citybaptism);
        setShepherdBaptism(data.shepherdbaptism);
        setHolySpirit(data.holyspirit);
        setPositionSheld(data.positionsheld);
        setHowMeetLord(data.howmeetlord);
        setAddNote(data.addnote);
        setPhoto(data.photo);
        setEmail(data.email);
        setFirma(data.firma);
        Helpers.postAuth("/family/all", "POST", token, {})
          .then((data) => {
            setFamilys(data);
          })
          .catch(() => {
            setFamilys([]);
          });
        setLoading(false);
      });
    } catch {}
  }, [token, props]);

  const showAlert = (texto) => {
    setTextAlert(texto);
    setAlertOpen(true);
    setTimeout(() => {
      setAlertOpen(false);
    }, 3000);
  };

  const TooltipButton = (props) => {
    if (props.text) {
      return (
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip-2">{props.text}</Tooltip>}
        >
          {props.children}
        </OverlayTrigger>
      );
    } else {
      return null;
    }
  };

  const updateMember = () => {
    Helpers.postAuth("/members/update", "POST", token, {
      id: props.Member.selectId,
      photo,
      names,
      lastnames,
      typedoc,
      numdoc,
      nationality,
      placeofbirth,
      dateofbirth,
      gender,
      address,
      phonenumber,
      civilstatus,
      placefamily,
      scholarship,
      occupation,
      datewaterbaptism,
      citybaptism,
      shepherdbaptism,
      holyspirit,
      positionsheld,
      howmeetlord,
      addnote,
      firma,
    }).then((data, err) => {
      if (err) throw err;
      if (data.modifiedCount === 1) {
        setVariantAlert("success");
        showAlert("Cambios Guardados con Exito");
        setTimeout(() => {
          props.Vista.setMemberView(0);
        }, 3000);
      } else {
        setVariantAlert("info");
        showAlert("Sin Cambios");
      }
    });
  };

  const removeMember = () => {
    Helpers.postAuth("/members/del", "POST", token, {
      id: props.Sucursal.selectId,
    }).then((data, err) => {
      if (err) {
        setShowModal(false);
        setVariantAlert("danger");
        showAlert("Error Eliminando Sucursal");
      } else {
        setShowModal(false);
        setVariantAlert("success");
        showAlert("Sucursal Eliminada con Exito");
        setTimeout(() => {
          props.Vista.setMemberView(0);
        }, 3000);
      }
    });
  };

  const Dictado = () => {
    switch (indice) {
      case 1:
        return <Dictaphone foco={focusedInputRef} onChange={setNames} />;
      case 2:
        return <Dictaphone foco={focusedInputRef} onChange={setLastNames} />;
      case 3:
        return <Dictaphone foco={focusedInputRef} onChange={setNumDoc} />;
      case 5:
        return <Dictaphone foco={focusedInputRef} onChange={setPlaceOfBirth} />;
      case 6:
        return <Dictaphone foco={focusedInputRef} onChange={setAddress} />;
      case 7:
        return <Dictaphone foco={focusedInputRef} onChange={setPhoneNumber} />;
      case 8:
        return <Dictaphone foco={focusedInputRef} onChange={setCityBaptism} />;
      case 9:
        return (
          <Dictaphone foco={focusedInputRef} onChange={setShepherdBaptism} />
        );
      case 10:
        return (
          <Dictaphone foco={focusedInputRef} onChange={setPositionSheld} />
        );
      case 11:
        return <Dictaphone foco={focusedInputRef} onChange={setHowMeetLord} />;
      case 12:
        return <Dictaphone foco={focusedInputRef} onChange={setAddNote} />;
      default:
        return null;
    }
  };

  return (
    <div>
      {loading && (
        <div
          style={{
            zIndex: 1000,
            width: "100vw",
            height: "100vh",
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "rgba(255,255,255,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <Commet color="#32cd32" size="medium" text="" textColor="" />
          </div>
        </div>
      )}
      <Collapse
        in={alertOpen}
        dimension={"height"}
        timeout={300}
        className="fixed-top p-3 m-2 rounded"
      >
        <Alert
          variant={variantAlert}
          className="fw-bold text-center"
          style={{
            display: alertOpen ? "block" : "none",
          }}
          id="example-collapse-text"
        >
          {textAlert}
        </Alert>
      </Collapse>

      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        animation={true}
      >
        <Modal.Header className="border-0 bg-danger text-light">
          <Modal.Title>Eliminar Membresía</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>¿Esta Seguro que Desea Eliminar esta Membresía?</p>
        </Modal.Body>

        <Modal.Footer className="border-0">
          <Button
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              setShowModal(false);
            }}
          >
            Cerrar
          </Button>
          <Button
            variant="danger"
            onClick={(e) => {
              e.preventDefault();
              removeMember();
            }}
          >
            Si, Eliminar
          </Button>
        </Modal.Footer>
      </Modal>

      <Card className="shadow-sm border-0 bg-transparent">
        <Card.Header className="border-0">
          <Button
            onClick={(e) => {
              e.preventDefault();
              props.Vista.setMemberView(0);
            }}
            variant="transparent"
            className="fw-bold"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="fa-fw me-2" />
            Información de la Membresía
          </Button>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <Card className="shadow-sm border-0 bg-transparent">
                <Card.Body>
                  <Row>
                    <Col
                      sm={3}
                      className="mb-3"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <Image
                        src={photo}
                        style={{
                          width: "100%",
                          height: "auto",
                          borderRadius: 10,
                          marginBottom: 10,
                        }}
                      />
                      {ronly === false && (
                        <>
                          <input
                            type="file"
                            ref={fileRef}
                            className="invisible"
                            accept="image/png, image/jpeg"
                            value=""
                            onChange={(e) => {
                              e.preventDefault();
                              const reader = new FileReader();
                              let baseString;
                              reader.onloadend = function () {
                                baseString = reader.result;
                                setPhoto(baseString);
                              };
                              reader.readAsDataURL(e.target.files[0]);
                            }}
                          />
                          <Button
                            className="btn btn-lg bg-primary shadow w-100"
                            variant="light"
                            onClick={() => {
                              fileRef.current.click();
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faCamera}
                              className="fa-fw"
                              color="white"
                              beat
                            />{" "}
                          </Button>
                        </>
                      )}
                    </Col>
                    <Col sm={9}>
                      <Row>
                        <Col sm={6}>
                          <InputGroup className="mb-3">
                            <TooltipButton text="Nombres">
                              <InputGroup.Text
                                id="basic-addon1"
                                style={{ width: 50, justifyContent: "center" }}
                              >
                                <FontAwesomeIcon
                                  icon={faUser}
                                  className="fa-fw"
                                />
                              </InputGroup.Text>
                            </TooltipButton>
                            <Form.Control
                              value={names}
                              onChange={(e) => {
                                e.preventDefault();
                                setNames(e.target.value);
                              }}
                              tabIndex={1}
                              onFocus={handleFocus}
                              readOnly={ronly}
                            />
                            {indice === 1 && ronly === false && (
                              <InputGroup.Text className="p-0 d-flex">
                                <Dictado />
                              </InputGroup.Text>
                            )}
                          </InputGroup>
                        </Col>
                        <Col sm={6}>
                          <InputGroup className="mb-3">
                            <TooltipButton text="Apellidos">
                              <InputGroup.Text
                                id="basic-addon1"
                                style={{ width: 50, justifyContent: "center" }}
                              >
                                <FontAwesomeIcon
                                  icon={faUser}
                                  className="fa-fw"
                                />
                              </InputGroup.Text>
                            </TooltipButton>
                            <Form.Control
                              value={lastnames}
                              onChange={(e) => {
                                e.preventDefault();
                                setLastNames(e.target.value);
                              }}
                              tabIndex={2}
                              onFocus={handleFocus}
                              readOnly={ronly}
                            />
                            {indice === 2 && ronly === false && (
                              <InputGroup.Text className="p-0 d-flex">
                                <Dictado />
                              </InputGroup.Text>
                            )}
                          </InputGroup>
                        </Col>
                        <Col sm={6}>
                          <InputGroup className="mb-3">
                            <TooltipButton text="Tipo de Documento">
                              <InputGroup.Text
                                id="basic-addon1"
                                style={{ width: 50, justifyContent: "center" }}
                              >
                                <FontAwesomeIcon
                                  icon={faIdCard}
                                  className="fa-fw"
                                />
                              </InputGroup.Text>
                            </TooltipButton>
                            <Form.Select
                              value={typedoc}
                              onChange={(e) => {
                                e.preventDefault();
                                setTypeDoc(e.target.value);
                              }}
                              disabled={ronly}
                            >
                              <option value="">Seleccione...</option>
                              <option value="PASAPORTE">PASAPORTE</option>
                              <option value="RUT">RUT</option>
                            </Form.Select>
                          </InputGroup>
                        </Col>
                        <Col sm={6}>
                          <InputGroup className="mb-3">
                            <TooltipButton text="Número de Documento">
                              <InputGroup.Text
                                id="basic-addon1"
                                style={{ width: 50, justifyContent: "center" }}
                              >
                                <FontAwesomeIcon
                                  icon={faIdCard}
                                  className="fa-fw"
                                />
                              </InputGroup.Text>
                            </TooltipButton>
                            <Form.Control
                              value={numdoc}
                              onChange={(e) => {
                                e.preventDefault();
                                setNumDoc(e.target.value);
                              }}
                              tabIndex={3}
                              onFocus={handleFocus}
                              readOnly={ronly}
                            />
                            {indice === 3 && ronly === false && (
                              <InputGroup.Text className="p-0 d-flex">
                                <Dictado />
                              </InputGroup.Text>
                            )}
                          </InputGroup>
                        </Col>
                        <Col sm={6}>
                          <InputGroup className="mb-3">
                            <TooltipButton text="Nacionalidad">
                              <InputGroup.Text
                                id="basic-addon1"
                                style={{ width: 50, justifyContent: "center" }}
                              >
                                <FontAwesomeIcon
                                  icon={faEarthAmericas}
                                  className="fa-fw"
                                />
                              </InputGroup.Text>
                            </TooltipButton>
                            <Form.Select
                              value={nationality}
                              onChange={(e) => {
                                setNationality(e.target.value);
                              }}
                              disabled={ronly}
                            >
                              <option value="">Seleccione...</option>
                              <option value="Argentina">Argentina</option>
                              <option value="Bolivia">Bolivia</option>
                              <option value="Brasil">Brasil</option>
                              <option value="Chile">Chile</option>
                              <option value="Colombia">Colombia</option>
                              <option value="Costa Rica">Costa Rica</option>
                              <option value="Cuba">Cuba</option>
                              <option value="Ecuador">Ecuador</option>
                              <option value="El Salvador">El Salvador</option>
                              <option value="Guatemala">Guatemala</option>
                            </Form.Select>
                          </InputGroup>
                        </Col>
                        <Col sm={6}>
                          <InputGroup className="mb-3">
                            <TooltipButton text="Ciudad de Nacimiento">
                              <InputGroup.Text
                                id="basic-addon1"
                                style={{ width: 50, justifyContent: "center" }}
                              >
                                <FontAwesomeIcon
                                  icon={faGlobe}
                                  className="fa-fw"
                                />
                              </InputGroup.Text>
                            </TooltipButton>
                            <Form.Control
                              value={placeofbirth}
                              onChange={(e) => {
                                e.preventDefault();
                                setPlaceOfBirth(e.target.value);
                              }}
                              tabIndex={5}
                              onFocus={handleFocus}
                              readOnly={ronly}
                            />
                            {indice === 5 && ronly === false && (
                              <InputGroup.Text className="p-0 d-flex">
                                <Dictado />
                              </InputGroup.Text>
                            )}
                          </InputGroup>
                        </Col>
                        <Col sm={6}>
                          <InputGroup className="mb-3">
                            <TooltipButton text="Fecha de Nacimiento">
                              <InputGroup.Text
                                id="basic-addon1"
                                style={{ width: 50, justifyContent: "center" }}
                              >
                                <FontAwesomeIcon
                                  icon={faCalendar}
                                  className="fa-fw"
                                />
                              </InputGroup.Text>
                            </TooltipButton>
                            <Form.Control
                              type="date"
                              value={dateofbirth}
                              onChange={(e) => {
                                e.preventDefault();
                                setDateOfBirth(e.target.value);
                              }}
                              readOnly={ronly}
                            />
                          </InputGroup>
                        </Col>
                        <Col sm={6}>
                          <InputGroup className="mb-3">
                            <TooltipButton text="Género">
                              <InputGroup.Text
                                id="basic-addon1"
                                style={{ width: 50, justifyContent: "center" }}
                              >
                                <FontAwesomeIcon
                                  icon={faVenusMars}
                                  className="fa-fw"
                                />
                              </InputGroup.Text>
                            </TooltipButton>
                            <Form.Select
                              value={gender}
                              onChange={(e) => {
                                e.preventDefault();
                                setGender(e.target.value);
                              }}
                              disabled={ronly}
                            >
                              <option value="">Seleccione...</option>
                              <option value="Femenino">Femenino</option>
                              <option value="Masculino">Masculino</option>
                            </Form.Select>
                          </InputGroup>
                        </Col>
                        <Col sm={12}>
                          <InputGroup className="mb-3">
                            <TooltipButton text="Dirección">
                              <InputGroup.Text
                                id="basic-addon1"
                                style={{ width: 50, justifyContent: "center" }}
                              >
                                <FontAwesomeIcon
                                  icon={faMapLocation}
                                  className="fa-fw"
                                />
                              </InputGroup.Text>
                            </TooltipButton>
                            <Form.Control
                              value={address}
                              onChange={(e) => {
                                e.preventDefault();
                                setAddress(e.target.value);
                              }}
                              tabIndex={6}
                              onFocus={handleFocus}
                              readOnly={ronly}
                            />
                            {indice === 6 && ronly === false && (
                              <InputGroup.Text className="p-0 d-flex">
                                <Dictado />
                              </InputGroup.Text>
                            )}
                          </InputGroup>
                        </Col>
                        <Col sm={6}>
                          <InputGroup className="mb-3">
                            <TooltipButton text="Número de Teléfono">
                              <InputGroup.Text
                                id="basic-addon1"
                                style={{ width: 50, justifyContent: "center" }}
                              >
                                <FontAwesomeIcon
                                  icon={faPhone}
                                  className="fa-fw"
                                />
                              </InputGroup.Text>
                            </TooltipButton>
                            <Form.Control
                              value={phonenumber}
                              onChange={(e) => {
                                e.preventDefault();
                                setPhoneNumber(e.target.value);
                              }}
                              tabIndex={7}
                              onFocus={handleFocus}
                              readOnly={ronly}
                            />
                            {indice === 7 && ronly === false && (
                              <InputGroup.Text className="p-0 d-flex">
                                <Dictado />
                              </InputGroup.Text>
                            )}
                          </InputGroup>
                        </Col>
                        <Col sm={6}>
                          <InputGroup>
                            <TooltipButton text="Correo Electrónico">
                              <InputGroup.Text
                                id="basic-addon1"
                                style={{ width: 50, justifyContent: "center" }}
                              >
                                <FontAwesomeIcon
                                  icon={faEnvelope}
                                  className="fa-fw"
                                />
                              </InputGroup.Text>
                            </TooltipButton>
                            <Form.Control
                              value={email}
                              onChange={(e) => {
                                e.preventDefault();
                                setEmail(e.target.value);
                              }}
                              onFocus={handleFocus}
                              readOnly={ronly}
                              alt="Correo Electrónico"
                            />
                          </InputGroup>
                        </Col>
                        <Col sm={6}>
                          <InputGroup className="mb-3">
                            <TooltipButton text="Estado Civil">
                              <InputGroup.Text
                                id="basic-addon1"
                                style={{ width: 50, justifyContent: "center" }}
                              >
                                <FontAwesomeIcon
                                  icon={faStreetView}
                                  className="fa-fw"
                                />
                              </InputGroup.Text>
                            </TooltipButton>
                            <Form.Select
                              value={civilstatus}
                              onChange={(e) => {
                                e.preventDefault();
                                setCivilStatus(e.target.value);
                              }}
                              disabled={ronly}
                            >
                              <option value="">Seleccione...</option>
                              <option value="Soltero(a)">Soltero(a)</option>
                              <option value="Casado(a)">Casado(a)</option>
                              <option value="Viudo(a)">Viudo(a)</option>
                              <option value="Divorciado(a)">
                                Divorciado(a)
                              </option>
                            </Form.Select>
                          </InputGroup>
                        </Col>
                        <Col sm={6}>
                          <InputGroup className="mb-3">
                            <TooltipButton text="Grupo Familiar">
                              <InputGroup.Text
                                id="basic-addon1"
                                style={{ width: 50, justifyContent: "center" }}
                              >
                                <FontAwesomeIcon
                                  icon={faPeopleRoof}
                                  className="fa-fw"
                                />
                              </InputGroup.Text>
                            </TooltipButton>
                            <Form.Select
                              value={groupFamily}
                              onChange={(e) => {
                                e.preventDefault();
                                setGroupFamily(e.target.value);
                              }}
                              disabled={ronly}
                            >
                              <option value="">Selecciona...</option>
                              {familys.map((data, index) => (
                                <option key={index}>{data.name_family}</option>
                              ))}
                            </Form.Select>
                          </InputGroup>
                        </Col>
                        <Col sm={6}>
                          <InputGroup className="mb-3">
                            <TooltipButton text="Posición Familiar">
                              <InputGroup.Text
                                id="basic-addon1"
                                style={{ width: 50, justifyContent: "center" }}
                              >
                                <FontAwesomeIcon
                                  icon={faPeopleRoof}
                                  className="fa-fw"
                                />
                              </InputGroup.Text>
                            </TooltipButton>
                            <Form.Select
                              value={placefamily}
                              onChange={(e) => {
                                e.preventDefault();
                                setPlaceFamily(e.target.value);
                              }}
                              disabled={ronly}
                            >
                              <option value="">Seleccione...</option>
                              <option value="Padre">Padre</option>
                              <option value="Madre">Madre</option>
                              <option value="Hijo(a)">Hijo(a)</option>
                              <option value="Sobrino(a)">Sobrino(a)</option>
                              <option value="Primo(a)">Primo(a)</option>
                              <option value="Hermano(a)">Hermano(a)</option>
                              <option value="Abuelo(a)">Abuelo(a)</option>
                              <option value="Otro">Otro</option>
                            </Form.Select>
                          </InputGroup>
                        </Col>
                        <Col sm={6}>
                          <InputGroup className="mb-3">
                            <TooltipButton text="Nivel de Estudios">
                              <InputGroup.Text
                                id="basic-addon1"
                                style={{ width: 50, justifyContent: "center" }}
                              >
                                <FontAwesomeIcon
                                  icon={faGraduationCap}
                                  className="fa-fw"
                                />
                              </InputGroup.Text>
                            </TooltipButton>
                            <Form.Select
                              value={scholarship}
                              onChange={(e) => {
                                e.preventDefault();
                                setScholarShip(e.target.value);
                              }}
                              disabled={ronly}
                            >
                              <option value="">Seleccione...</option>
                              <option value="Pre-Escolar">Pre-Escolar</option>
                              <option value="Enseñanza Básica">
                                Enseñanza Básica
                              </option>
                              <option value="Enseñanza Media">
                                Enseñanza Media
                              </option>
                              <option value="Educación Superior">
                                Educación Superior
                              </option>
                              <option value="Post-Titulos">Post-Titulos</option>
                            </Form.Select>
                          </InputGroup>
                        </Col>
                        <Col sm={6}>
                          <InputGroup className="mb-3">
                            <TooltipButton text="Ocupación">
                              <InputGroup.Text
                                id="basic-addon1"
                                style={{ width: 50, justifyContent: "center" }}
                              >
                                <FontAwesomeIcon
                                  icon={faUserDoctor}
                                  className="fa-fw"
                                />
                              </InputGroup.Text>
                            </TooltipButton>
                            <Form.Select
                              value={occupation}
                              onChange={(e) => {
                                e.preventDefault();
                                setOccupation(e.target.value);
                              }}
                              disabled={ronly}
                            >
                              <option value="">Seleccione...</option>
                              <option value="Hogar">Hogar</option>
                              <option value="Independiente">
                                Independiente
                              </option>
                              <option value="Empleado">Empleado</option>
                            </Form.Select>
                          </InputGroup>
                        </Col>
                        <Col sm={6}>
                          <InputGroup className="mb-3">
                            <TooltipButton text="Sucursal">
                              <InputGroup.Text
                                id="basic-addon1"
                                style={{ width: 50, justifyContent: "center" }}
                              >
                                <FontAwesomeIcon
                                  icon={faCodeBranch}
                                  className="fa-fw"
                                />
                              </InputGroup.Text>
                            </TooltipButton>
                            <Form.Control
                              value={congregation}
                              onChange={(e) => {
                                e.preventDefault();
                                setCongregation(e.target.value);
                              }}
                              readOnly={ronly}
                              disabled
                            />
                          </InputGroup>
                        </Col>
                        <Col sm={6}>
                          <InputGroup className="mb-3">
                            <TooltipButton text="Lugar del Bautismo">
                              <InputGroup.Text
                                id="basic-addon1"
                                style={{ width: 50, justifyContent: "center" }}
                              >
                                <FontAwesomeIcon
                                  icon={faGlobe}
                                  className="fa-fw"
                                />
                              </InputGroup.Text>
                            </TooltipButton>
                            <Form.Control
                              value={citybaptism}
                              onChange={(e) => {
                                e.preventDefault();
                                setCityBaptism(e.target.value);
                              }}
                              tabIndex={8}
                              onFocus={handleFocus}
                              readOnly={ronly}
                            />
                            {indice === 8 && ronly === false && (
                              <InputGroup.Text className="p-0 d-flex">
                                <Dictado />
                              </InputGroup.Text>
                            )}
                          </InputGroup>
                        </Col>
                        <Col sm={6}>
                          <InputGroup className="mb-3">
                            <TooltipButton text="Fecha del Bautismo">
                              <InputGroup.Text
                                id="basic-addon1"
                                style={{ width: 50, justifyContent: "center" }}
                              >
                                <FontAwesomeIcon
                                  icon={faCalendarDays}
                                  className="fa-fw"
                                />
                              </InputGroup.Text>
                            </TooltipButton>
                            <Form.Control
                              type="date"
                              value={datewaterbaptism}
                              onChange={(e) => {
                                e.preventDefault();
                                setDateWaterBaptism(e.target.value);
                              }}
                              readOnly={ronly}
                            />
                          </InputGroup>
                        </Col>
                        <Col sm={6}>
                          <InputGroup className="mb-3">
                            <TooltipButton text="Quien lo Bautizó">
                              <InputGroup.Text
                                id="basic-addon1"
                                style={{ width: 50, justifyContent: "center" }}
                              >
                                <FontAwesomeIcon
                                  icon={faPersonPraying}
                                  className="fa-fw"
                                />
                              </InputGroup.Text>
                            </TooltipButton>
                            <Form.Control
                              value={shepherdbaptism}
                              onChange={(e) => {
                                e.preventDefault();
                                setShepherdBaptism(e.target.value);
                              }}
                              tabIndex={9}
                              onFocus={handleFocus}
                              readOnly={ronly}
                            />
                            {indice === 9 && ronly === false && (
                              <InputGroup.Text className="p-0 d-flex">
                                <Dictado />
                              </InputGroup.Text>
                            )}
                          </InputGroup>
                        </Col>
                        <Col sm={6}>
                          <InputGroup className="mb-3">
                            <TooltipButton text="Cuando Recibió el Espíritu Santo">
                              <InputGroup.Text
                                id="basic-addon1"
                                style={{ width: 50, justifyContent: "center" }}
                              >
                                <FontAwesomeIcon
                                  icon={faHandsHolding}
                                  className="fa-fw"
                                />
                              </InputGroup.Text>
                            </TooltipButton>
                            <Form.Control
                              type="date"
                              value={holyspirit}
                              onChange={(e) => {
                                e.preventDefault();
                                setHolySpirit(e.target.value);
                              }}
                              readOnly={ronly}
                            />
                          </InputGroup>
                        </Col>
                        <Col sm={12}>
                          <InputGroup className="mb-3">
                            <TooltipButton text="Cargos Desempeñados">
                              <InputGroup.Text
                                id="basic-addon1"
                                style={{ width: 50, justifyContent: "center" }}
                              >
                                <FontAwesomeIcon
                                  icon={faPersonWalking}
                                  className="fa-fw"
                                />
                              </InputGroup.Text>
                            </TooltipButton>
                            <Form.Control
                              value={positionsheld}
                              onChange={(e) => {
                                e.preventDefault();
                                setPositionSheld(e.target.value);
                              }}
                              tabIndex={10}
                              onFocus={handleFocus}
                              readOnly={ronly}
                            />
                            {indice === 10 && ronly === false && (
                              <InputGroup.Text className="p-0 d-flex">
                                <Dictado />
                              </InputGroup.Text>
                            )}
                          </InputGroup>
                        </Col>
                        <Col sm={12}>
                          <InputGroup className="mb-3">
                            <TooltipButton text="Como Conoció al Señor">
                              <InputGroup.Text
                                id="basic-addon1"
                                style={{ width: 50, justifyContent: "center" }}
                              >
                                <FontAwesomeIcon
                                  icon={faBookBible}
                                  className="fa-fw"
                                />
                              </InputGroup.Text>
                            </TooltipButton>
                            <Form.Control
                              value={howmeetlord}
                              onChange={(e) => {
                                e.preventDefault();
                                setHowMeetLord(e.target.value);
                              }}
                              tabIndex={11}
                              onFocus={handleFocus}
                              readOnly={ronly}
                            />
                            {indice === 11 && ronly === false && (
                              <InputGroup.Text className="p-0 d-flex">
                                <Dictado />
                              </InputGroup.Text>
                            )}
                          </InputGroup>
                        </Col>
                        <Col sm={12}>
                          <InputGroup className="mb-3">
                            <TooltipButton text="Notas Eventuales">
                              <InputGroup.Text
                                id="basic-addon1"
                                style={{ width: 50, justifyContent: "center" }}
                              >
                                <FontAwesomeIcon
                                  icon={faNoteSticky}
                                  className="fa-fw"
                                />
                              </InputGroup.Text>
                            </TooltipButton>
                            <Form.Control
                              value={addnote}
                              onChange={(e) => {
                                e.preventDefault();
                                setAddNote(e.target.value);
                              }}
                              tabIndex={12}
                              onFocus={handleFocus}
                              readOnly={ronly}
                            />
                            {indice === 12 && ronly === false && (
                              <InputGroup.Text className="p-0 d-flex">
                                <Dictado />
                              </InputGroup.Text>
                            )}
                          </InputGroup>
                        </Col>
                        <Col sm={12}>
                          <Firma dataSign={{firma, setFirma}} disable={ronly} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className="border-0">
                  <ButtonGroup className="w-100">
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        if (ronly === true) {
                          setRonly(false);
                        } else {
                          setRonly(true);
                          updateMember();
                        }
                      }}
                      variant={ronly ? "primary" : "success"}
                    >
                      {ronly ? (
                        <>
                          <FontAwesomeIcon
                            icon={faEdit}
                            className="fa-fw me-2"
                          />
                          Editar
                        </>
                      ) : (
                        <>
                          <FontAwesomeIcon
                            icon={faSave}
                            className="fa-fw me-2"
                          />
                          Guardar
                        </>
                      )}
                    </Button>
                    <Button
                      variant="danger"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowModal(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} className="fa-fw me-3" />
                      Eliminar
                    </Button>
                  </ButtonGroup>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="border-0"></Card.Footer>
      </Card>
    </div>
  );
}

// eslint-disable-next-line
export default MembersView;

import React from "react";
import MainLayout from "../../layouts/MainLayout";

function Financial() {
  return (
    <div>
      <MainLayout>
        Reportes Financieros
      </MainLayout>
    </div>
  );
}

export default Financial;

import React from "react";
import MainLayout from "../../layouts/MainLayout";
import RolesAdd from "./RolesAdd"
import RolesList from "./RolesList"

function Roles() {

  const [RollView, setRollView] = React.useState(0)
  const [selectId, setSelectId] = React.useState(null);


  return (
    <div>
      <MainLayout>
        
      {
        RollView === 0 ? <RolesList Vista={{ RollView, setRollView }} Roll={{ selectId, setSelectId }} /> : 
        RollView === 1 ? <RolesAdd Vista={{ RollView, setRollView }} /> : ''
        }
        
      </MainLayout>
    </div>
  );
}

export default Roles;

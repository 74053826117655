import React from "react";
import MainLayout from "../../layouts/MainLayout";
import DocsAdd from "./DocsAdd";
import DocsList from "./DocsList";
import NewCert from "./NewCert";
import NewDoc from "./NewDoc";
import DocsViewPDF from "./DocsViewPDF";
import CompileDoc from "./CompileDoc";

function Docs() {
  const [DocView, setDocView] = React.useState(0);
  const [selectId, setSelectId] = React.useState(null);
  const [base, setBase] = React.useState(null);

  return (
    <div>
      <MainLayout>
        {DocView === 0 ? (
          <DocsList
            Vista={{ DocView, setDocView }}
            Documento={{ selectId, setSelectId }}
          />
        ) : DocView === 1 ? (
          <DocsAdd Vista={{ DocView, setDocView }} />
        ) : DocView === 2 ? (
          <NewCert Vista={{ DocView, setDocView }} />
        ) : DocView === 3 ? (
          <NewDoc Vista={{ DocView, setDocView }} />
        ) : DocView === 4 ? (
          <CompileDoc
            Vista={{ DocView, setDocView }}
            Documento={{ selectId, setSelectId }}
            backBase={{ base, setBase }}
          />
        ) : DocView === 5 ? (
          <DocsViewPDF
            Vista={{ DocView, setDocView }}
            Documento={{ selectId, setSelectId }}
            backBase={{ base, setBase }}
          />
        ) : (
          ""
        )}
      </MainLayout>
    </div>
  );
}

export default Docs;

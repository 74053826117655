import React from "react";
import {
  Card,
  Row,
  Col,
  Image,
  InputGroup,
  Form,
  Button,
  ButtonGroup,
  Collapse,
  Alert,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faChevronLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import Helpers from "../../libs/Helpers";

function UsersAdd(props) {
  const token = localStorage.getItem("session");
  const [roles, setRoles] = React.useState([]);
  const [licencias, setLicencias] = React.useState([]);
  const [sucursales, setSucursales] = React.useState([]);

  const [nombres, setNombres] = React.useState();
  const [apellidos, setApellidos] = React.useState();
  const [correo, setCorreo] = React.useState();
  const [telefono, setTelefono] = React.useState();
  const [licencia, setLicencia] = React.useState();
  const [roller, setRoller] = React.useState();
  const [rut, setRut] = React.useState();
  const [estado, setEstado] = React.useState();
  const [foto, setFoto] = React.useState();
  const [secret, setSecret] = React.useState();
  const [newsecret, setNewSecret] = React.useState();
  const [congregation, setCongregation] = React.useState();
  const fileRef = React.useRef();

  const [alertOpen, setAlertOpen] = React.useState(false);
  const [textAlert, setTextAlert] = React.useState();
  const [variantAlert, setVariantAlert] = React.useState("warning");

  React.useEffect(() => {
    Helpers.postAuth("/roles/all", "POST", token, {}).then((data) => {
      setRoles(data);
    });
    Helpers.postAuth("/licencias/all", "POST", token, {}).then((data) => {
      setLicencias(data);
    });
    Helpers.postAuth("/branches/all", "POST", token, {}).then((data)=> {
      setSucursales(data);
    })
  }, [token]);

  const showAlert = (texto) => {
    setTextAlert(texto);
    setAlertOpen(true);
    setTimeout(() => {
      setAlertOpen(false);
    }, 3000);
  };

  const updateAdmin = () => {
    if (secret !== newsecret) {
      setVariantAlert("warning");
      showAlert("Las Claves no Coinciden");
    } else if (
      !nombres ||
      !apellidos ||
      !correo ||
      !telefono ||
      !rut ||
      !foto ||
      !secret ||
      !congregation
    ) {
      setVariantAlert("warning");
      showAlert("Debes Ingresar Todos los Datos");
    } else {
      Helpers.postAuth("/admins/add", "POST", token, {
        names: nombres,
        lastnames: apellidos,
        email: correo,
        phonenumber: telefono,
        licencia,
        roll: roller,
        rut,
        status: estado,
        secret,
        imagen64: foto,
        congregation
      }).then((data, err) => {
        if (err) throw err;
        if (data._id) {
          setVariantAlert("info");
          showAlert("Usuario Creadon con Exito");
          setTimeout(()=>{
            props.Vista.setUserView(0);
          },3000)
        } else {
          setVariantAlert("warning");
          showAlert("Este Usuario ya Existe");
        }
      });
    }
  };

  return (
    <div>
      <Collapse
        in={alertOpen}
        dimension={"height"}
        timeout={300}
        className="fixed-top p-3 m-2 rounded"
      >
        <Alert
          variant={variantAlert}
          className="fw-bold text-center"
          style={{
            display: alertOpen ? "block" : "none",
          }}
          id="example-collapse-text"
        >
          {textAlert}
        </Alert>
      </Collapse>
      <Card className="shadow-sm border-0 bg-transparent">
        <Card.Header className="fw-bold border-0">
        <Button
            onClick={(e) => {
              e.preventDefault();
              props.Vista.setUserView(0);
            }}
            variant="transparent"
            className="fw-bold"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="fa-fw me-2" />
            Agregar Nuevo Usuario
          </Button>
        </Card.Header>
        <Card.Body>
          <form onSubmit={(e) => e.preventDefault()}>
            <Row>
              <Col sm={12} md={3} lg={2} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                flexDirection: 'column'
              }}>
                <Image
                  width={150}
                  height={200}
                  src={foto}
                  style={{
                    boxShadow: '0px 0px 10px #AABBCC',
                    borderRadius: 10
                  }}
                />
                <input
                  type="file"
                  ref={fileRef}
                  className="invisible"
                  accept="image/png, image/jpeg"
                  value=""
                  onChange={(e) => {
                    e.preventDefault();
                    const reader = new FileReader();
                    let baseString;
                    reader.onloadend = function () {
                      baseString = reader.result;
                      setFoto(baseString);
                    };
                    reader.readAsDataURL(e.target.files[0]);
                  }}
                />
                <Button
                  className="btn btn-sm mt-4 shadow-sm"
                  variant="dark"
                  onClick={() => {
                    fileRef.current.click();
                  }}
                >
                  <FontAwesomeIcon icon={faCamera} className="fa-fw me-2" />{" "}
                  Cargar Foto
                </Button>
              </Col>
              <Col>
                <Card className="shadow-sm border-0 bg-transparent">
                  <Card.Body>
                    <Row>
                      <Col sm={6}>
                        <InputGroup className="mb-3">
                          <InputGroup.Text
                            id="basic-addon1"
                            className="colLeft"
                          >
                            Nombres
                          </InputGroup.Text>
                          <Form.Control
                            value={nombres}
                            autoComplete="no"
                            onChange={(e) => {
                              e.preventDefault();
                              setNombres(e.target.value);
                            }}
                          />
                        </InputGroup>
                      </Col>
                      <Col sm={6}>
                        <InputGroup className="mb-3">
                          <InputGroup.Text
                            id="basic-addon1"
                            className="colLeft"
                          >
                            Apellidos
                          </InputGroup.Text>
                          <Form.Control
                            value={apellidos}
                            autoComplete="no"
                            onChange={(e) => {
                              e.preventDefault();
                              setApellidos(e.target.value);
                            }}
                          />
                        </InputGroup>
                      </Col>
                      <Col sm={6}>
                        <InputGroup className="mb-3">
                          <InputGroup.Text
                            id="basic-addon1"
                            className="colLeft"
                          >
                            Correo
                          </InputGroup.Text>
                          <Form.Control
                            value={correo}
                            autoComplete="no"
                            onChange={(e) => {
                              e.preventDefault();
                              setCorreo(e.target.value);
                            }}
                          />
                        </InputGroup>
                      </Col>
                      <Col sm={6}>
                        <InputGroup className="mb-3">
                          <InputGroup.Text
                            id="basic-addon1"
                            className="colLeft"
                          >
                            Teléfono
                          </InputGroup.Text>
                          <Form.Control
                            value={telefono}
                            autoComplete="no"
                            onChange={(e) => {
                              e.preventDefault();
                              setTelefono(e.target.value);
                            }}
                          />
                        </InputGroup>
                      </Col>
                      <Col sm={6}>
                        <InputGroup className="mb-3">
                          <InputGroup.Text
                            id="basic-addon1"
                            className="colLeft"
                          >
                            Licencia
                          </InputGroup.Text>
                          <Form.Select
                            value={licencia}
                            onChange={(e) => {
                              e.preventDefault();
                              setLicencia(e.target.value);
                            }}
                          >
                            {licencias.map((datos, i) => (
                              <option
                                key={i}
                                value={datos.grant}
                                {...(datos.licencia === licencia
                                  ? "selected"
                                  : "")}
                              >
                                {datos.licencia}
                              </option>
                            ))}
                          </Form.Select>
                        </InputGroup>
                      </Col>
                      <Col sm={6}>
                        <InputGroup className="mb-3">
                          <InputGroup.Text
                            id="basic-addon1"
                            className="colLeft"
                          >
                            Roll
                          </InputGroup.Text>
                          <Form.Select
                            value={roller}
                            onChange={(e) => {
                              e.preventDefault();
                              setRoller(e.target.value);
                            }}
                          >
                            {roles.map((datos, i) => (
                              <option
                                key={i}
                                value={datos.grant}
                                {...(datos.grant === roller ? "selected" : "")}
                              >
                                {datos.roll}
                              </option>
                            ))}
                          </Form.Select>
                        </InputGroup>
                      </Col>
                      <Col sm={6}>
                        <InputGroup className="mb-3">
                          <InputGroup.Text
                            id="basic-addon1"
                            className="colLeft"
                          >
                            RUT
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            value={rut}
                            autoComplete="no"
                            onChange={(e) => {
                              e.preventDefault();
                              setRut(e.target.value);
                            }}
                          />
                        </InputGroup>
                      </Col>
                      <Col sm={6}>
                        <InputGroup className="mb-3">
                          <InputGroup.Text
                            id="basic-addon1"
                            className="colLeft"
                          >
                            Estado
                          </InputGroup.Text>
                          <Form.Select
                            value={estado}
                            onChange={(e) => {
                              e.preventDefault();
                              setEstado(e.target.value);
                            }}
                          >
                            <option value="0">Inactivo</option>
                            <option value="1">Activo</option>
                          </Form.Select>
                        </InputGroup>
                      </Col>
                      <Col sm={6}>
                        <InputGroup className="mb-3">
                          <InputGroup.Text
                            id="basic-addon1"
                            className="colLeft"
                          >
                            Clave
                          </InputGroup.Text>
                          <Form.Control
                            type="password"
                            autoComplete="no"
                            value={secret}
                            onChange={(e) => {
                              e.preventDefault();
                              setSecret(e.target.value);
                            }}
                          />
                        </InputGroup>
                      </Col>
                      <Col sm={6}>
                        <InputGroup className="mb-3">
                          <InputGroup.Text
                            id="basic-addon1"
                            className="colLeft"
                          >
                            Repetir
                          </InputGroup.Text>
                          <Form.Control
                            type="password"
                            autoComplete="no"
                            value={newsecret}
                            onChange={(e) => {
                              e.preventDefault();
                              setNewSecret(e.target.value);
                            }}
                          />
                        </InputGroup>
                      </Col>
                      <Col sm={12}>
                            <Form.Select value={congregation} onChange={(e)=>setCongregation(e.target.value)}>
                              <option value="">Seleccione...</option>
                              {
                                sucursales.map((suc, id)=>(
                                  <option key={id} value={suc.nombre_sucursal}>{suc.nombre_sucursal}</option>
                                ))
                              }
                            </Form.Select>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer className="border-0">
                    <ButtonGroup className="w-100">
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          updateAdmin();
                        }}
                      >
                        <FontAwesomeIcon icon={faSave} className="fa-fw me-2" />
                        Guardar
                      </Button>
                    </ButtonGroup>
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </form>
        </Card.Body>
        <Card.Footer className="border-0"></Card.Footer>
      </Card>
    </div>
  );
}

export default UsersAdd;

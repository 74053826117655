import React from "react";
import { MapContainer, TileLayer, Popup, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import icono from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import L from "leaflet";

function Mapa(props) {
  const latitude = parseFloat(props.latitud);
  const longitude = parseFloat(props.longitud);
  const refMarker = React.useRef(null);

  const icon = L.icon({
    iconUrl: icono,
    shadowUrl: iconShadow,
  });

  React.useEffect(() => {
    if (refMarker.current) {
        refMarker.current.openPopup();
      }
  }, []);

  return (
    <div>
      <MapContainer
        center={[latitude, longitude]}
        zoom={16}
        scrollWheelZoom={false}
        style={{ height: "250px" }}
      >
        <TileLayer
          attribution=""
          url="http://mt0.google.com/vt/lyrs=m&hl=es&x={x}&y={y}&z={z}"
        />
        <Marker position={[latitude, longitude]} icon={icon} ref={refMarker}>
          <Popup>
            {props.nombre_sucursal}
            <br />
            {props.direccion}
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
}

export default Mapa;

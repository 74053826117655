import React, { useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  InputGroup,
  Form,
  Collapse,
  Alert,
  ButtonGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import Helpers from "../../libs/Helpers";

function FamilyAdd(props) {
  const token = localStorage.getItem("session");

  const [name_family, setNameFamily] = useState();
  //const [congregation, setCongregation] = useState();

  const [alertOpen, setAlertOpen] = React.useState(false);
  const [textAlert, setTextAlert] = useState();
  const [variantAlert, setVariantAlert] = React.useState("warning");

  //const [sucursales, setSucursales] = useState([]);

  /*useEffect(() => {
    Helpers.postAuth("/branches/all", "POST", token, {}).then((data1) => {
      setSucursales(data1);
    });
  }, [token]);*/

  const showAlert = (texto) => {
    setTextAlert(texto);
    setAlertOpen(true);
    setTimeout(() => {
      setAlertOpen(false);
    }, 3000);
  };

  const validateInput = (msg) => {
    setVariantAlert("warning");
    showAlert(msg);
  };

  const addMember = () => {
    !name_family
      ? validateInput("Debes Ingresar el Nombre de la Familia")
      : Helpers.postAuth("/family/add", "POST", token, {
          name_family,
        }).then((data, err) => {
          if (err) throw err;
          setVariantAlert("info");
          showAlert("Registro Creado con Exito");
          setTimeout(() => {
            props.Vista.setFamilyViewScreen(0);
          }, 3000);
        });

        /*
        !congregation
      ? validateInput("Debes Seleccionar la Congregación a la que pertenece")
      : 
        */
  };

  return (
    <div>
      <Collapse
        in={alertOpen}
        dimension={"height"}
        timeout={300}
        className="fixed-top p-3 m-2 rounded"
      >
        <Alert
          variant={variantAlert}
          className="fw-bold text-center"
          style={{
            display: alertOpen ? "block" : "none",
          }}
          id="example-collapse-text"
        >
          {textAlert}
        </Alert>
      </Collapse>
      <Card className="shadow-sm border-0 bg-transparent">
        <Card.Header>
          <Button
            onClick={(e) => {
              e.preventDefault();
              props.Vista.setFamilyViewScreen(0);
            }}
            variant="transparent"
            className="fw-bold"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="fa-fw me-2" />
            Agregar Nueva Familia
          </Button>
        </Card.Header>
        <Card.Body>
          <form onSubmit={(e) => e.preventDefault()}>
            <Row>
              <Col sm={12} md={12} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Nombre Familia
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    required
                    value={name_family}
                    onChange={(e) => {
                      setNameFamily(e.target.value);
                    }}
                    className="bg-transparent"
                  />
                </InputGroup>
              </Col>
            </Row>

            <Row>
              <Col sm={12} className="mt-4">
                <ButtonGroup>
                  <Button
                    className="w-100"
                    onClick={(e) => {
                      e.preventDefault();
                      addMember();
                    }}
                  >
                    <FontAwesomeIcon icon={faSave} className="fa-fw me-2" />{" "}
                    Guardar Resgistro
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </form>
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
    </div>
  );
}

export default FamilyAdd;

/*

<Col sm={12} md={12} className="mt-2">
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" className="colLeftM">
                    Congregación
                  </InputGroup.Text>
                  <Form.Select
                    required
                    value={congregation}
                    onChange={(e) => {
                      setCongregation(e.target.value);
                    }}
                    className="bg-transparent"
                  >
                    <option value="">Seleccione...</option>
                    {sucursales.map((data, id) => (
                      <option key={id} value={data.nombre_sucursal}>
                        {data.nombre_sucursal}
                      </option>
                    ))}
                  </Form.Select>
                </InputGroup>
              </Col>

*/

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowLeft,
  faArrowRight,
  faKey,
} from "@fortawesome/free-solid-svg-icons";
import LoginLayout from "../../layouts/LoginLayout";
import { Button, Collapse, ButtonGroup } from "react-bootstrap";
import Helpers from "../../libs/Helpers";
import { useNavigate } from "react-router-dom";


function MainLogin() {
  const [forgot, setForgot] = React.useState(false);
  const [viewEmail, setViewEmail] = React.useState(true);
  const [viewPassword, setViewPassword] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [emailForgot, setEmailForgot] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [textAlert, setTextAlert] = React.useState();
  const [variantAlert, setVariantAlert] = React.useState("bg-warning");
  const navigation = new useNavigate();

  const showAlert = (texto) => {
    setTextAlert(texto);
    setAlertOpen(true);
    setTimeout(() => {
      setAlertOpen(false);
    }, 3000);
  };




  const nextStep = (e) => {
    e.preventDefault();
    if (email || password) {
      if (viewEmail) {
        setViewEmail(!viewEmail);
        setViewPassword(!viewPassword);
      } else if (password) {
        Helpers.queryNoAuth("/admins/login", "POST", {
          email,
          password,
        }).then(async (data) => {
          
          if (data) {
            if (data.token) {
              //console.log('Datos de la Sesion:', await Helpers.getUserData(data.token))
              localStorage.setItem("session", data.token);
              Helpers.getUserData(data.token).then((data0)=>{
                Helpers.getRoll(data0.roll).then((val)=>{
                  localStorage.setItem("cargo", val)
                })
                localStorage.setItem("userData", JSON.stringify(data0))
              })
              
              
              setVariantAlert("bg-info");
              showAlert("Iniciando...");
              setTimeout(() => {
                navigation(0);
              }, 3000);
            } else {
              setVariantAlert("bg-warning");
              showAlert("Información Incorrecta, Verifique e Intente Nuevamente");  
            }
          } else {
            setVariantAlert("bg-warning");
            showAlert("Información Incorrecta, Verifique e Intente Nuevamente");
          }
        });
      } else {
        setVariantAlert("bg-warning");
        showAlert("Ingresa la Contraseña");
      }
    } else {
      setVariantAlert("bg-warning");
      showAlert("Ingresa el Correo Electrónico");
    }
  };

  const sendForgot = () => {
    if (emailForgot) {
      setVariantAlert("bg-info");
      showAlert("Enviando Correo de Recuperación");
      setTimeout(()=>{
      Helpers.queryNoAuth("/admins/recover", "POST", { email: emailForgot }).then(()=>{
        setVariantAlert("bg-info");
        showAlert("Revisa tu Buzón de Correo y Sigue las Instrucciones");
        setTimeout(()=>{
          navigation(0)
        },3000)
      })
    },3000)
    } else {
      setVariantAlert("bg-warning");
      showAlert("Ingresa el Correo Electrónico");
    }
  };

  return (
    <LoginLayout>
      <Collapse
        in={alertOpen}
        dimension={"height"}
        timeout={300}
        className="fixed-top p-3 m-2 rounded"
      >
        <div
          className={[variantAlert, "fw-bold text-center"]}
          id="example-collapse-text"
        >
          {textAlert}
        </div>
      </Collapse>
      <form onSubmit={(e) => e.preventDefault()}>
        {forgot ? (
          <div className="loginForm">
            <footer className="footer navbar-fixed-bottom text-center">
              <div className="input-group mb-3">
                <span className="input-group-text bg-primary" id="basic-addon1">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    color="white"
                    className="fa-fw"
                  />
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Correo Electrónico"
                  aria-label="Email"
                  aria-describedby="basic-addon1"
                  value={emailForgot}
                  onChange={(e) => {
                    setEmailForgot(e.target.value);
                  }}
                />
              </div>
              <div className="btn-group w-100 mb-4">
                <button
                  className="btn btn-info"
                  onClick={(e) => {
                    e.preventDefault();
                    sendForgot();
                  }}
                >
                  <FontAwesomeIcon icon={faPaperPlane} className="me-2" />
                  Enviar Correo
                </button>
              </div>
              <div className="small">
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    setEmail("");
                    setPassword("");
                    setViewEmail(true);
                    setViewPassword(false);
                    setForgot(false);
                  }}
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "blue",
                  }}
                >
                  Intentar Nuevamente
                </div>
              </div>
            </footer>
          </div>
        ) : (
          <div className="loginForm">
            {viewEmail && (
              <div className="input-group mb-3">
                <span className="input-group-text bg-primary" id="basic-addon1">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    color="white"
                    className="fa-fw fa-1x"
                  />
                </span>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Correo Electrónico"
                  aria-label="Email"
                  aria-describedby="basic-addon1"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            )}
            {viewPassword && (
              <div className="input-group mb-3">
                <span className="input-group-text bg-primary" id="basic-addon1">
                  <FontAwesomeIcon
                    icon={faKey}
                    color="white"
                    className="fa-fw fa-1x"
                  />
                </span>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Contraseña"
                  autoComplete="no"
                  aria-label="Password"
                  aria-describedby="basic-addon1"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            )}
            <footer className="footer navbar-fixed-bottom text-center">
              <ButtonGroup className="w-100 mb-4">
                {!viewEmail && (
                  <Button
                    type="button"
                    variant="success"
                    onClick={(e) => {
                      e.preventDefault();
                      setViewEmail(true);
                      setViewPassword(false);
                      setEmail("");
                      setPassword("");
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
                    Volver
                  </Button>
                )}
                <Button
                  type="button"
                  variant="info"
                  onClick={(e) => {
                    nextStep(e);
                  }}
                  aria-controls="example-collapse-text"
                  aria-expanded={alertOpen}
                >
                  {viewEmail ? "Continuar" : "Loguearse"}
                  <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
                </Button>
              </ButtonGroup>
              <div className="small">
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    setForgot(true);
                    setEmailForgot("");
                  }}
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "blue",
                  }}
                >
                  Recuperar Contraseña
                </div>
              </div>
            </footer>
          </div>
        )}
      </form>
    </LoginLayout>
  );
}

export default MainLogin;

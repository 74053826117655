import React from "react";
import MainLayout from "../../layouts/MainLayout";
import DistritosList from "./DistritosList";
import DistritosAdd from "./DistritosAdd";

function Distritos() {
  let [BranchView, setBranchView] = React.useState(0);
  const [selectId, setSelectId] = React.useState(null);

  return (
    <div>
      <MainLayout>
        {BranchView === 0 ? (
          <DistritosList
            Vista={{ BranchView, setBranchView }}
            Sucursal={{ selectId, setSelectId }}
          />
        ) : BranchView === 1 ? (
          <DistritosAdd
            Vista={{ BranchView, setBranchView }}
            Sucursal={{ selectId, setSelectId }}
          />
        )
         : ""
        }
      </MainLayout>
    </div>
  );
}

// eslint-disable-next-line
export default Distritos;

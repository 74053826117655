import React from "react";
import MainLayout from "../layouts/MainLayout";


function Home() {
  
  return (
    <div>
      <MainLayout>
        Saludos
      </MainLayout>
    </div>
  );
}

export default Home;
